import styled from 'styled-components/macro'

import { colors } from '../../globals/variables'

const Title = styled.div({
  color: colors.defaultBlue,
  fontSize: '28px',
  marginBottom: '30px',
})

export default Title
