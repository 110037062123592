import React from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components/macro'
import { Mutation } from 'react-apollo'

import {
  UPDATE_TEAM_ACCESS,
  DELETE_TEAM_ACCESS,
} from '../../../../graphql/mutations'
import { ORGANIZATION } from '../../../graphql/queries'

import TextOnlyButton from '../../../../globals/components/TextOnlyButton'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'

const Content = styled.span(({ isactive }) => ({
  opacity: isactive === 'false' ? '0.5' : 1,
}))

const TeamSectionRow = ({
  item: { id, firstName, lastName, email, role, isActive },
  teamId,
  organizationId,
}) => {
  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Content isactive={isActive.toString()}>{id}</Content>
      </Table.Cell>
      <Table.Cell>
        <Content isactive={isActive.toString()}>
          {firstName} {lastName}
        </Content>
      </Table.Cell>
      <Table.Cell>
        <Content isactive={isActive.toString()}>{email}</Content>
      </Table.Cell>
      <Table.Cell>
        <Content isactive={isActive.toString()}>{role}</Content>
      </Table.Cell>
      <Table.Cell>
        <ModalWithFormAndMutation
          mutation={UPDATE_TEAM_ACCESS}
          modalTitle={`Edit user ${firstName} ${lastName}`}
          additionalVariables={{ userId: id, teamId }}
          modalTrigger={props => (
            <TextOnlyButton {...props}>Edit</TextOnlyButton>
          )}
          fields={[
            {
              inputType: 'selectInput',
              value: { role },
              options: [
                { value: 'admin', text: 'Admin' },
                { value: 'member', text: 'Member' },
              ],
            },
            {
              inputType: 'selectInput',
              value: { isActive },
              options: [
                { value: true, text: 'true' },
                { value: false, text: 'false' },
              ],
            },
          ]}
        />
      </Table.Cell>
      <Table.Cell>
        <Mutation
          mutation={DELETE_TEAM_ACCESS}
          variables={{ userId: id, teamId }}
          refetchQueries={[
            {
              query: ORGANIZATION,
              variables: { id: organizationId },
            },
          ]}
        >
          {(mutationFunction, { data }) => (
            <TextOnlyButton
              style={{ color: 'salmon' }}
              onClick={mutationFunction}
            >
              Remove from team
            </TextOnlyButton>
          )}
        </Mutation>
      </Table.Cell>
    </Table.Row>
  )
}

export default TeamSectionRow
