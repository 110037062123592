import React from 'react'
import { NamespacesConsumer } from 'react-i18next'

const NoSurvey = () => (
  <NamespacesConsumer>
    {t => (
      <div
        style={{
          textAlign: 'center',
          margin: '50px',
        }}
      >
        {t('survey.noSurvey.message')}
      </div>
    )}
  </NamespacesConsumer>
)

export default NoSurvey
