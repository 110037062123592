import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'

const SectionTitle = styled.div({
  fontSize: '25px',
  borderBottom: `1px solid ${colors.generalGreyBorder}`,
  display: 'flex',
  paddingTop: '40px',
  paddingBottom: '20px',
  alignItems: 'baseline',
})

export default SectionTitle
