import React from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'

import { colors, mobileWidth } from '../../globals/variables'

const borderColor = '#ddd'

const MediaQueryWrapper = styled.div`
  @media (max-width: ${mobileWidth}) {
    margin-bottom: 125px;
  }
`
const Wrapper = styled(MediaQueryWrapper)({
  marginTop: '20px',
  marginBottom: '175px',
  display: 'flex',
  justifyContent: 'center',
})
const Bar = styled.div({
  display: 'flex',
  border: '1px solid',
  borderColor: borderColor,
  borderRadius: '15px',
})
const MediaQueryBullet = styled.div`
  @media (max-width: ${mobileWidth}) {
    padding-left: 25px;
  }
`
const Bullet = styled(MediaQueryBullet)(({ first, passed, current }) => ({
  display: 'flex',
  paddingLeft: '45px',
  alignItems: 'center',
  paddingTop: '4px',
  ...(first && {
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
  }),
  ...(passed && {
    backgroundColor: '#f0f9fe',
    paddingTop: '0px',
  }),
  ...(current && {
    borderRight: '1px solid',
    borderColor: borderColor,
  }),
}))
const MediaQueryTimeEstimate = styled.div`
  @media (max-width: ${mobileWidth}) {
    margin-left: 25px;
  }
`
const TimeEstimate = styled(MediaQueryTimeEstimate)({
  borderLeft: '1px solid',
  borderColor: borderColor,
  padding: '5px 10px 2px  ',
  fontSize: '12px',
  marginLeft: '45px',
  lineHeight: '17px',
})
const TimeEstimateWrapper = styled.div({
  marginLeft: 'auto',
})
// const StyledLink = styled(Link)(({ passed }) => ({
const StyledLink = styled.span(({ passed }) => ({
  textDecoration: 'none',
  color: colors.generalFontGrey,
  ...(passed && {
    color: colors.defaultBlue,
  }),
}))
const CurrentBullet = styled.div({
  backgroundColor: '#fff',
  borderColor: `${colors.defaultBlue} !important`,
  border: '3px solid',
  height: '6px',
  width: '6px',
  borderRadius: '6px',
  marginRight: '-7px',
})

const getCurrentPrinciple = (principles, step) =>
  principles.reduce((acc, current) => {
    return Number(step) >= (current.position || 0) ? current : acc
  }, {})

const Timeline = ({
  principleTimeline,
  match: {
    params: { step },
  },
}) => {
  const currentPrinciple = getCurrentPrinciple(principleTimeline, step)
  return (
    <Wrapper data-testid={'timeline'}>
      <Bar>
        {principleTimeline.map((item, index) => {
          const passed = item.position <= currentPrinciple.position
          const isCurrent = currentPrinciple.principleId === item.principleId
          return (
            <Bullet
              current={isCurrent}
              first={index === 0}
              passed={passed}
              key={index}
            >
              {isCurrent ? (
                <CurrentBullet />
              ) : (
                <StyledLink passed={passed ? 1 : 0} to={`${item.position}`}>
                  •
                </StyledLink>
              )}
            </Bullet>
          )
        })}
        <TimeEstimateWrapper>
          <TimeEstimate>
            ~{principleTimeline.length * 2}
            MIN
          </TimeEstimate>
        </TimeEstimateWrapper>
      </Bar>
    </Wrapper>
  )
}
export default withRouter(Timeline)
