import { NamespacesConsumer } from 'react-i18next'
import React from 'react'
import styled from 'styled-components/macro'

import Button from '../common/Button'
import Title from '../common/Title'
import fixDropboxLink from '../common/fixDropboxLink'
import image from '../../globals/images/glitter-sesame-street-wand.gif'

const Image = styled.img({
  marginTop: '30px',
  width: '100%',
})

const End = () => (
  <NamespacesConsumer>
    {t => (
      <div data-testid={'endScreen'}>
        <Title>{t('survey.end.mainTitle')}</Title>
        <div>{t('survey.end.message')}</div>
        <Image src={fixDropboxLink(image)} />
        <Button
          style={{ display: 'inline-block', marginTop: 30 }}
          as="a"
          href="https://habitud.se"
        >
          {t('survey.end.button')}
        </Button>
      </div>
    )}
  </NamespacesConsumer>
)

export default End
