import React from 'react'
import styled from 'styled-components/macro'

const Button = styled.button({
  color: '#fff',
  backgroundColor: '#1890ff',
  borderColor: '#1890ff',
  textShadow: '0 -1px 0 rgba(0, 0, 0, 0.12)',
  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.035)',
  lineHeight: '1.5',
  display: 'inline-block',
  fontWeight: '400',
  textAlign: 'center',
  MsTouchAction: 'manipulation',
  touchAction: 'manipulation',
  cursor: 'pointer',
  border: '1px solid #0000',
  whiteSpace: 'nowrap',
  padding: '0 15px',
  fontSize: '14px',
  borderRadius: '4px',
  height: '32px',
  userSelect: 'none',
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  position: 'relative'
})

const FormButton = ({ children, ...rest }) => (
  <Button {...rest}>{children}</Button>
)

export default FormButton
