import React from 'react'
import { Table } from 'semantic-ui-react'

import { UPDATE_USER } from '../../../../graphql/mutations'
import { DELETE_USER } from '../../../graphql/mutations'
import { ORGANIZATION } from '../../../graphql/queries'

import TextOnlyButton from '../../../../globals/components/TextOnlyButton'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'

export const userFormFields = (
  { firstName = null, lastName = null, email = null, password = null } = {},
  isPasswordVisible = true
) =>
  [
    {
      inputType: 'text',
      value: { firstName: firstName || '' },
    },
    {
      inputType: 'text',
      value: { lastName: lastName || '' },
    },
    {
      inputType: 'text',
      value: { email: email || '' },
    },
    isPasswordVisible && {
      inputType: 'text',
      value: { password: password || '' },
    },
  ].filter(Boolean)

const UserSectionRow = ({
  user: { id, firstName, lastName, email, password, defaultTeam },
  organizationId,
}) => {
  return (
    <Table.Row key={id}>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>
        {firstName} {lastName}
      </Table.Cell>
      <Table.Cell title={email}>{email}</Table.Cell>
      <Table.Cell>{defaultTeam ? defaultTeam.name : '---'}</Table.Cell>
      <Table.Cell>
        <ModalWithFormAndMutation
          mutation={UPDATE_USER}
          modalTitle={`Edit user ${firstName} ${lastName}`}
          additionalVariables={{ id }}
          modalTrigger={props => (
            <TextOnlyButton {...props}>Edit</TextOnlyButton>
          )}
          fields={userFormFields(
            { firstName, lastName, email, password },
            false
          )}
          bottomRight={
            !defaultTeam && {
              refetchQueries: {
                query: ORGANIZATION,
                variables: { id: organizationId },
              },
              mutationObj: {
                mutation: DELETE_USER,
                id,
              },
            }
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default UserSectionRow
