import { roundOffToDecimals } from '../../../../globals/utilFunctions'

export default ({ points, normValue }) => {
  let difference = points - normValue
  let positive = true
  if (difference < 0) {
    positive = false
    difference = difference - difference - difference
  }
  difference = roundOffToDecimals({ number: difference, decimals: 0 })
  return [difference, positive]
}
