import React from 'react'
import { Mutation } from 'react-apollo'
import styled from 'styled-components/macro'

import { SET_DEFAULT_TEAM } from '../../graphql/mutations'
import { CURRENT_USER } from '../../graphql/queries'
import ControlledSelectInput from '../../globals/components/form-elements/ControlledSelectInput'

const StyledControlledSelectInput = styled(ControlledSelectInput)`
  select {
    margin-bottom: 0;
    height: 30px;
    min-width: 90px;
  }
`

const handleChange = ({ event, mutationFunction, userId }) => {
  mutationFunction({ variables: { teamId: event.target.value, userId } }).then(
    ({ data }) => {
      localStorage.setItem('token', data.setDefaultTeam.token)
      window.location.reload()
    }
  )
}

const TeamSelector = ({ teamAccesses, currentUser, ...rest }) => {
  let defaultTeamId = teamAccesses.find(ta => ta.isDefaultTeam).team.id
  let teamOptions = teamAccesses.map(ta => {
    return {
      value: ta.team.id,
      text: ta.team.name,
    }
  })
  return (
    <Mutation
      mutation={SET_DEFAULT_TEAM}
      refetchQueries={[{ query: CURRENT_USER }]}
    >
      {(mutationFunction, { error, loading }) => {
        if (error) return null
        if (loading) {
          defaultTeamId = 'loading'
          teamOptions = [{ value: 'loading', text: 'Loading...' }]
        }
        return (
          <StyledControlledSelectInput
            {...rest}
            name={'key'}
            selectedOption={defaultTeamId}
            options={teamOptions}
            controlFunc={event =>
              handleChange({ event, mutationFunction, userId: currentUser.id })
            }
          />
        )
      }}
    </Mutation>
  )
}

export default TeamSelector
