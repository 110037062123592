import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, withRouter } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import HomeLogo from '../../globals/components/HabitudLogo'
import { colors } from '../common/variables'
import { colors as globalColors } from '../../globals/variables'
import ArrowLeft from '../common/svgs/ArrowLeft'
import TeamSelector from './TeamSelector'
import Signout from '../../auth/Signout'
import TextOnlyButton from '../../globals/components/TextOnlyButton'

const Wrapper = styled.div({
  height: '70px',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  borderBottom: '1px solid #eee',
})
const InnerWrapper = styled.div({
  width: '925px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '15px',
})
const ItemsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})
const Item = styled.div`
  margin-left: 20px;
  color: #333;
  font-weight: 600;
  color: ${globalColors.generalFontGrey};
  &.current,
  &:active {
    color: ${globalColors.defaultBlue};
    text-decoration: none;
  }
`
const StyledArrow = styled.div({
  color: colors.generalFontBlack,
  display: 'flex',
  alignItems: 'center;',
})

const isSubPage = ({ pathname }) => {
  const firstPartOfPathname = pathname.split('/')[1]
  if (['conducted-survey', 'report'].includes(firstPartOfPathname)) {
    return '/'
  }
}

const Navbar = ({ currentUser, location }) => {
  const { pathname } = location
  const { firstName, teamAccesses } = currentUser
  return (
    <NamespacesConsumer>
      {t => (
        <Wrapper>
          {isSubPage({ pathname }) ? (
            <InnerWrapper>
              <NavLink to={'/'}>
                <StyledArrow>
                  <ArrowLeft style={{ marginRight: 10 }} />{' '}
                  {t('navigation.back')}
                </StyledArrow>
              </NavLink>
            </InnerWrapper>
          ) : (
            <InnerWrapper>
              <NavLink to={'/'}>
                <HomeLogo />
              </NavLink>
              <ItemsWrapper>
                <Item
                  as={NavLink}
                  location={location}
                  activeClassName="current"
                  exact
                  to="/"
                >
                  {t('navigation.home')}
                </Item>
                <Item
                  as={NavLink}
                  location={location}
                  activeClassName="current"
                  exact
                  to={`/members`}
                >
                  {t('navigation.members')}
                </Item>
                {teamAccesses &&
                  teamAccesses.length > 1 && (
                    <div style={{ marginLeft: 20 }}>
                      <TeamSelector
                        teamAccesses={teamAccesses}
                        currentUser={currentUser}
                      />
                    </div>
                  )}
                <Item
                  as={NavLink}
                  location={location}
                  activeClassName="current"
                  exact
                  to={`/account`}
                >
                  {firstName}
                </Item>
                <Signout
                  someRenderProps={props => (
                    <Item as={TextOnlyButton} {...props}>
                      {t('navigation.logOut')}
                    </Item>
                  )}
                />

                {/* <DrawerMenu openingComponent={<Item>{firstName}</Item>} /> */}
              </ItemsWrapper>
            </InnerWrapper>
          )}
        </Wrapper>
      )}
    </NamespacesConsumer>
  )
}

export default withRouter(Navbar)
