import { gql } from 'apollo-boost'

import { fragments, secondaryFragments } from './fragments'

export const NORM_VALUES = gql`
  query {
    team {
      survey {
        id
        principles {
          id
          position
          internalTitle
          publicTitle
          subScales {
            normValue
          }
        }
      }
    }
  }
`
export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      email
      isActive
      locale
      defaultTeam {
        ...CompleteTeam
      }
      teamAccesses {
        isDefaultTeam
        isActive
        role
        team {
          id
          name
          isActivated
          activeUsers {
            ...CompleteUser
          }
          users {
            ...CompleteUser
          }
        }
      }
    }
  }
  ${secondaryFragments.team}
  ${fragments.user}
`
export const RESPONDENT = gql`
  query($conductedSurveyIdentifier: String!, $respondentIdentifier: String!) {
    respondent(
      conductedSurveyIdentifier: $conductedSurveyIdentifier
      respondentIdentifier: $respondentIdentifier
    ) {
      id
      isFinished
      responses {
        id
        value
        question {
          id
        }
      }
      conductedSurvey {
        id
        isActive
        locale
        survey {
          principles {
            id
            publicTitle
            description
            imageUrl
            subScales {
              questions {
                id
                text
                helpText
                scaleEnds
                scaleWidth
                principleTitle
              }
            }
          }
        }
      }
    }
  }
`
export const CONDUCTED_SURVEY = gql`
  query($id: ID!) {
    conductedSurvey(id: $id) {
      ...CompleteConductedSurvey
      respondents {
        ...CompleteRespondent
      }
    }
  }
  ${fragments.conductedSurvey}
  ${secondaryFragments.respondent}
`
export const CONDUCTED_SURVEYS = gql`
  query {
    conductedSurveys {
      ...CompleteConductedSurvey
      respondents {
        ...CompleteRespondent
        user {
          ...CompleteUser
        }
      }
    }
  }
  ${fragments.conductedSurvey}
  ${fragments.user}
  ${secondaryFragments.respondent}
`
export const TEAM = gql`
  query {
    team {
      ...CompleteTeam
      users {
        ...UserWithTeamAccess
      }
    }
  }
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
`
export const TEAM_WITH_ORGANIZATION = gql`
  query {
    team {
      ...CompleteTeam
      orgUsers {
        ...CompleteUser
      }
      users {
        ...UserWithTeamAccess
      }
    }
  }
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
  ${fragments.user}
`
