import React from 'react'
import { Query } from 'react-apollo'

import { CREATE_ORGANIZATION } from '../../graphql/mutations'
import { ORGANIZATIONS } from '../../graphql/queries'
import { SURVEYS } from '../../graphql/survey-constructor/queriesAndMutations'

import arrayOfTrueFalseSelectOptions from '../../common/arrayOfTrueFalseSelectOptions'
import Organizations from './Organizations'
import AddButton, { AddWrapper } from '../../common/AddButton'
import ModalWithFormAndMutation from '../../common/form/ModalWithFormAndMutation'
import Preloader from '../../../globals/components/Preloader'
import Error from '../../../globals/components/Error'

const organizationFields = data => [
  {
    inputType: 'text',
    value: { name: '' },
  },
  {
    inputType: 'selectInput',
    value: { isActivated: false },
    options: arrayOfTrueFalseSelectOptions,
  },
  {
    inputType: 'selectInput',
    value: { isTestPurpose: false },
    options: arrayOfTrueFalseSelectOptions,
  },
  {
    inputType: 'selectInput',
    value: { locale: 'en' },
    options: [
      { value: 'en', text: 'English' },
      { value: 'sv', text: 'Swedish' },
    ],
  },
  {
    inputType: 'selectInput',
    value: { surveyId: 1 },
    options: data.surveys.map(item => ({
      value: item.id,
      text: `${item.title} (v. ${item.version})`,
    })),
  },
]

const OrganizationsContainer = () => (
  <Query query={ORGANIZATIONS}>
    {({ loading, error, data }) => {
      if (loading) return <Preloader />
      if (error) return <Error />
      const { organizations } = data
      return (
        <>
          <AddWrapper>
            <Query query={SURVEYS}>
              {({ loading, error, data }) => {
                if (!Object.keys(data).length) return null
                return (
                  <ModalWithFormAndMutation
                    mutation={CREATE_ORGANIZATION}
                    modalTitle="Create new organization"
                    refetchQueries={[{ query: ORGANIZATIONS }]}
                    modalTrigger={props => (
                      <AddButton {...props}>New organization</AddButton>
                    )}
                    fields={organizationFields(data)}
                  />
                )
              }}
            </Query>
          </AddWrapper>
          <Organizations
            sheetTitle="All organizations"
            items={organizations.filter(item => !item.isTestPurpose)}
          />
          <Organizations
            sheetTitle="Test organizations"
            items={organizations.filter(item => item.isTestPurpose)}
          />
        </>
      )
    }}
  </Query>
)

export default OrganizationsContainer
