import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import { DELETE_CONDUCTED_SURVEY } from '../../../../graphql/mutations'
import { CONDUCTED_SURVEYS } from '../../../../graphql/queries'

import { colors } from '../../../../globals/variables'
// import { capitalize } from '../../../../globals/utilFunctions'
import ArrowRight from '../../../common/svgs/ArrowRight'
import doneRate from '../../conducted-surveys/bits/doneRate'
import DirectActionMutation from '../../../common/DirectActionMutation'
import ActionButton from '../../../common/ActionButton'

const Item = styled.div(({ isactivestatus, invalid }) => ({
  display: 'flex',
  border: `1px solid ${colors.generalGreyBorder}`,
  padding: '12px 15px',
  borderRadius: '5px',
  marginBottom: '20px',
  borderLeft: '5px solid',
  color: 'inherit',
  alignItems: 'center',
  borderLeftColor: isactivestatus ? colors.screamingGreen : '#657787',
  ...(invalid && {
    backgroundColor: '#eee',
  }),
}))
const Title = styled.div({
  width: '200px',
  fontWeight: '600',
})
const Date = styled.div({
  width: '200px',
})
const DoneRate = styled.div({
  width: '150px',
})
const Status = styled.div(({ isActive }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  width: '130px',
  justifyContent: 'flex-end',
  color: isActive && colors.screamingGreen,
}))
const StyledArrowRight = styled(ArrowRight)({
  height: '15px',
})
const RemoveButton = styled.div({
  marginLeft: 'auto',
  color: '#fa8072',
  marginRight: '8px',
})

const renderDate = ({ createdAt, endedAt, t }) => {
  if (endedAt) {
    return (
      t('home.surveyRowStatusEnded') +
      ' ' +
      moment(endedAt).format('YYYY-MM-DD')
    )
  }
  return (
    t('home.surveyRowStatusStarted') +
    ' ' +
    moment(createdAt).format('YYYY-MM-DD')
  )
}
const renderDoneRate = ({ respondents, isActive, t }) => {
  const { percentage } = doneRate({ respondents })
  return `${percentage} % ${
    isActive ? t('home.surveyRowHaveResponded') : t('home.surveyRowResponded')
  }`
}

const SurveySectionRow = ({
  createdAt,
  endedAt,
  isActive,
  respondents,
  id,
  title,
  result,
  teamId,
}) => {
  return (
    <NamespacesConsumer>
      {t => {
        if (isActive || (result && result.valid)) {
          const path =
            isActive === true ? `/conducted-survey/${id}` : `/report/${id}`
          return (
            <Item as={NavLink} to={path} isactivestatus={isActive ? 1 : 0}>
              <Title>
                {t('home.surveyRowTitle')} {title}
              </Title>
              <Date>{renderDate({ createdAt, endedAt, t })}</Date>
              <DoneRate>
                {renderDoneRate({ respondents, isActive, t })}
              </DoneRate>
              {isActive ? (
                <Status isActive>
                  {t('home.surveyRowStatusPending')} <StyledArrowRight />
                </Status>
              ) : (
                <Status>
                  {t('home.surveyRowStatusEnded')} <StyledArrowRight />
                </Status>
              )}
            </Item>
          )
        } else {
          return (
            <Item invalid isactivestatus={isActive ? 1 : 0}>
              <Title>
                {t('home.surveyRowTitleInvalid', { number: title })}
              </Title>
              <Date>{renderDate({ createdAt, endedAt, t })}</Date>
              <DoneRate>
                {renderDoneRate({ respondents, isActive, t })}
              </DoneRate>
              <DirectActionMutation
                mutation={DELETE_CONDUCTED_SURVEY}
                variables={{ id }}
                refetchQueries={[
                  { query: CONDUCTED_SURVEYS, variables: { teamId } },
                ]}
                modalTitle={t('home.modalTitleRemoveSurvey')}
                buttonText={t('remove')}
                successMsg={t('home.modalRemoveSurveySuccessMessage')}
                modalTrigger={props => {
                  return (
                    <RemoveButton as={ActionButton} {...props}>
                      {t('home.surveyRowStatusInvalid')}
                    </RemoveButton>
                  )
                }}
              />
            </Item>
          )
        }
      }}
    </NamespacesConsumer>
  )
}

export default SurveySectionRow
