import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import {
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
} from '../../graphql/mutations'
import { ORGANIZATIONS } from '../../graphql/queries'

import Sheet from '../../common/Sheet'
import ModalWithFormAndMutation from '../../common/form/ModalWithFormAndMutation'
import TextOnlyButton from '../../../globals/components/TextOnlyButton'
import OrganizationSvg from '../../svgs/filled/Organizations'

class Organizations extends Component {
  // state = {
  //   column: null,
  //   data: this.props.items,
  //   direction: null
  // }
  // handleSort = clickedColumn => () => {
  //   const { column, data, direction } = this.state
  //   if (column !== clickedColumn) {
  //     this.setState({
  //       column: clickedColumn,
  //       data: data.sort((a, b) =>
  //         a[clickedColumn].toString().localeCompare(b[clickedColumn].toString())
  //       ),
  //       direction: 'ascending'
  //     })
  //     return
  //   }
  //   this.setState({
  //     data: data.reverse(),
  //     direction: direction === 'ascending' ? 'descending' : 'ascending'
  //   })
  // }
  render() {
    const { sheetTitle, items } = this.props
    return (
      <Sheet
        title={sheetTitle}
        icon={<OrganizationSvg />}
        data-testid={'allOrganizations'}
      >
        <Table sortable celled fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Teams</Table.HeaderCell>
              <Table.HeaderCell>Users</Table.HeaderCell>
              <Table.HeaderCell>Locale</Table.HeaderCell>
              <Table.HeaderCell>Is activated</Table.HeaderCell>
              <Table.HeaderCell>Survey</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map(
              ({ id, name, teams, isActivated, survey, users, locale }) => (
                <Table.Row key={id}>
                  <Table.Cell>
                    <TextOnlyButton as={Link} to={`/sa/organizations/${id}`}>
                      {name}
                    </TextOnlyButton>
                  </Table.Cell>
                  <Table.Cell>{teams.length}</Table.Cell>
                  <Table.Cell>{users.length}</Table.Cell>
                  <Table.Cell>{locale}</Table.Cell>
                  <Table.Cell>{isActivated.toString()}</Table.Cell>
                  <Table.Cell>{survey.title}</Table.Cell>
                  <Table.Cell>
                    <ModalWithFormAndMutation
                      mutation={UPDATE_ORGANIZATION}
                      modalTitle={`Edit ${name}`}
                      additionalVariables={{ id }}
                      modalTrigger={props => (
                        <TextOnlyButton {...props}>Edit</TextOnlyButton>
                      )}
                      fields={[
                        {
                          inputType: 'text',
                          value: { name },
                        },
                        {
                          inputType: 'selectInput',
                          value: { isActivated },
                          options: [
                            { value: true, text: 'true' },
                            { value: false, text: 'false' },
                          ],
                        },
                      ]}
                      bottomRight={
                        !teams.length && {
                          refetchQueries: { query: ORGANIZATIONS },
                          mutationObj: {
                            mutation: DELETE_ORGANIZATION,
                            id,
                          },
                        }
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </Sheet>
    )
  }
}

export default Organizations
