import React from 'react'
import styled from 'styled-components/macro'

const Button = styled.button({
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
})

const TransparentButton = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>
}

export default TransparentButton
