import React from 'react'
import { BarLoader } from 'react-spinners'
import styled from 'styled-components/macro'

import { colors } from '../variables'

const Wrapper = styled.div({
  margin: '100px auto',
  width: '100px'
})

export default () => (
  <Wrapper>
    <BarLoader color={colors.defaultBlue} />
  </Wrapper>
)
