import React from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { NamespacesConsumer } from 'react-i18next'
import moment from 'moment'

import { END_CONDUCTED_SURVEY } from '../../../graphql/mutations'

import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'
import Sheet from '../../common/Sheet'
import SummarySection from './bits/SummarySection'
import RespondentListSection from './bits/RespondentListSection'
import MessageSection from './bits/MessageSection'
import Button from '../../common/Button'
import InnerSection from '../report/bits/SectionWrapper'
import { colors } from '../../../globals/variables'
import TopRightDate from '../report/bits/TopRightDate'

const InnerSectionLeftSide = styled.div({
  padding: '30px 30px 0px',
  backgroundColor: '#f5fbff',
  flex: 1,
})
const InnerSectionRightSide = styled.div({
  width: '175px',
  padding: '0 30px',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
const TwoLineSubSection = styled.div({
  marginBottom: '30px',
})
const SubSectionLabel = styled.div({
  color: colors.generalFontGrey,
})
const SubSectionValue = styled.div({
  fontSize: '25px',
  fontWeight: 600,
})

const handleClick = (mutationFunction, history) => {
  mutationFunction().then(({ data: { endConductedSurvey } }) => {
    if (endConductedSurvey.result.valid) {
      history.push(`/report/${endConductedSurvey.id}`)
    } else {
      history.push('/')
    }
  })
}

const ConductedSurvey = ({
  history,
  id,
  title,
  createdAt,
  doneCount,
  respondentsCount,
  percentage,
  respondents,
  conductedSurveyIdentifier,
  message,
}) => {
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => {
        moment.locale(i18n.language || 'en')
        return (
          <Sheet
            narrow
            subPage
            title={`${t('conductedSurvey.pending.mainTitle')} ${title}`}
            topRight={
              <TopRightDate>
                {t('conductedSurvey.pending.created')}{' '}
                {moment(createdAt).format('YYYY-MM-DD')}
              </TopRightDate>
            }
          >
            <InnerSection isWithBorder style={{ marginBottom: 50 }}>
              <InnerSectionLeftSide>
                <TwoLineSubSection>
                  <SubSectionLabel>
                    {t('conductedSurvey.pending.responseRate')}
                  </SubSectionLabel>
                  <SubSectionValue>
                    {t('conductedSurvey.pending.responseRateValues', {
                      doneCount,
                      respondentsCount,
                    })}
                  </SubSectionValue>
                </TwoLineSubSection>
                <TwoLineSubSection>
                  <SubSectionLabel>
                    {t('conductedSurvey.pending.responseRatePercentage')}
                  </SubSectionLabel>
                  <SubSectionValue>{percentage}%</SubSectionValue>
                </TwoLineSubSection>
                <TwoLineSubSection>
                  <SubSectionLabel>
                    {t('conductedSurvey.pending.timeSince')}
                  </SubSectionLabel>
                  <SubSectionValue>
                    {moment(createdAt).from(new Date())}
                  </SubSectionValue>
                </TwoLineSubSection>
              </InnerSectionLeftSide>
              <InnerSectionRightSide>
                <Mutation mutation={END_CONDUCTED_SURVEY} variables={{ id }}>
                  {(mutationFunction, { loading, error, data }) => {
                    if (loading) return <Preloader />
                    if (error) return <Error />
                    return (
                      <Button
                        onClick={event =>
                          handleClick(mutationFunction, history)
                        }
                        isGreen
                        isFullWidth
                      >
                        {t('conductedSurvey.pending.endSurvey')}
                      </Button>
                    )
                  }}
                </Mutation>
              </InnerSectionRightSide>
            </InnerSection>
            <SummarySection
              title={t('conductedSurvey.pending.sections.summaryTitle')}
              content={t('conductedSurvey.pending.sections.summaryContent', {
                respondentCount: t('personWithCount', {
                  count: respondents.length,
                }),
              })}
            />
            <RespondentListSection
              title={t('conductedSurvey.pending.sections.respondentsTitle')}
              respondents={respondents.map(
                ({ user, identifier, isFinished }) => {
                  return {
                    ...user,
                    identifier,
                    conductedSurveyIdentifier,
                    isFinished,
                  }
                }
              )}
            />
            <MessageSection
              title={t('conductedSurvey.pending.sections.messageTitle')}
              content={t('conductedSurvey.pending.sections.messageContent')}
              message={message}
            />
          </Sheet>
        )
      }}
    </NamespacesConsumer>
  )
}

export default withRouter(ConductedSurvey)
