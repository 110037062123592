import React from 'react'
import { Query } from 'react-apollo'
import { Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import {
  SURVEYS,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  DUPLICATE_SURVEY,
} from '../../graphql/survey-constructor/queriesAndMutations'

import Sheet from '../../common/Sheet'
import ModalWithFormAndMutation from '../../common/form/ModalWithFormAndMutation'
import TextOnlyButton from '../../../globals/components/TextOnlyButton'
import Preloader from '../../../globals/components/Preloader'
import Error from '../../../globals/components/Error'
import SurveyConstructorSvg from '../../svgs/filled/SurveyConstructor'

const SurveyConstructors = () => {
  return (
    <Sheet title="Survey versions" icon={<SurveyConstructorSvg />}>
      <Query query={SURVEYS}>
        {({ loading, error, data }) => {
          if (loading) return <Preloader />
          if (error) return <Error />
          return (
            <Table celled fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Version</Table.HeaderCell>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.surveys.map(
                  ({ id, title, version, principles, organizations }) => {
                    return (
                      <Table.Row key={id}>
                        <Table.Cell>{id}</Table.Cell>
                        <Table.Cell>
                          <TextOnlyButton
                            as={Link}
                            to={`/sa/survey-constructor/${id}`}
                          >
                            {title}
                          </TextOnlyButton>
                        </Table.Cell>
                        <Table.Cell>{version}</Table.Cell>
                        <Table.Cell>
                          <ModalWithFormAndMutation
                            mutation={UPDATE_SURVEY}
                            modalTitle={`Edit ${title}`}
                            additionalVariables={{ id }}
                            modalTrigger={props => (
                              <TextOnlyButton {...props}>Edit</TextOnlyButton>
                            )}
                            fields={[
                              {
                                inputType: 'text',
                                value: { title },
                              },
                              {
                                inputType: 'text',
                                value: { version },
                              },
                            ]}
                            bottomRight={
                              data.surveys.length > 1 &&
                              !organizations.length && {
                                refetchQueries: { query: SURVEYS },
                                mutationObj: {
                                  mutation: DELETE_SURVEY,
                                  id,
                                },
                              }
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <ModalWithFormAndMutation
                            mutation={DUPLICATE_SURVEY}
                            modalTitle={`Duplicate ${title}`}
                            additionalVariables={{ id }}
                            refetchQueries={[{ query: SURVEYS }]}
                            modalTrigger={props => (
                              <TextOnlyButton {...props}>
                                Duplicate
                              </TextOnlyButton>
                            )}
                            fields={[]}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }
                )}
              </Table.Body>
            </Table>
          )
        }}
      </Query>
    </Sheet>
  )
}

export default SurveyConstructors
