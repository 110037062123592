import React from 'react'
import { Query } from 'react-apollo'
import { Table } from 'semantic-ui-react'
import moment from 'moment'

import { ALL_CONDUCTED_SURVEYS } from '../../graphql/queries'
import { DELETE_CONDUCTED_SURVEY } from '../../../graphql/mutations'

import Sheet from '../../common/Sheet'
import Preloader from '../../../globals/components/Preloader'
import Error from '../../../globals/components/Error'
import TextOnlyButton from '../../../globals/components/TextOnlyButton'
import ModalWithFormAndMutation from '../../common/form/ModalWithFormAndMutation'
import ConductedSurveysSvg from '../../svgs/filled/ConductedSurveys'

export const renderStatusContent = result => {
  if (result) {
    if (result.valid) return result.totalSum
    if (!result.valid) return <span style={{ opacity: '0.6' }}>Invalid</span>
  }
  return <span style={{ opacity: '0.6' }}>Pending</span>
}

const Index = () => {
  return (
    <Sheet title="Conducted surveys" icon={<ConductedSurveysSvg />}>
      <Query query={ALL_CONDUCTED_SURVEYS}>
        {({ loading, error, data }) => {
          if (loading) return <Preloader />
          if (error) return <Error />
          return (
            <Table celled fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Result</Table.HeaderCell>
                  <Table.HeaderCell>Is active</Table.HeaderCell>
                  <Table.HeaderCell>Launched</Table.HeaderCell>
                  <Table.HeaderCell>Team</Table.HeaderCell>
                  <Table.HeaderCell>Org</Table.HeaderCell>
                  <Table.HeaderCell>Respondents</Table.HeaderCell>
                  <Table.HeaderCell>Finished rate</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.conductedSurveys.map(
                  ({
                    id,
                    isActive,
                    createdAt,
                    team: {
                      name: teamName,
                      organization: { name: organizationName },
                    },
                    respondents,
                    result,
                  }) => {
                    const respondentsCount = respondents.length
                    const percentageFinished = Math.floor(
                      (respondents.filter(r => r.isFinished).length /
                        respondentsCount) *
                        100
                    )
                    return (
                      <Table.Row key={id}>
                        <Table.Cell>{id}</Table.Cell>
                        <Table.Cell>{renderStatusContent(result)}</Table.Cell>
                        <Table.Cell>{isActive.toString()}</Table.Cell>
                        <Table.Cell>
                          {moment(createdAt).format('YYYY-MM-DD HH:mm')}
                        </Table.Cell>
                        <Table.Cell>{teamName}</Table.Cell>
                        <Table.Cell>{organizationName}</Table.Cell>
                        <Table.Cell>{respondentsCount}</Table.Cell>
                        <Table.Cell>{percentageFinished} %</Table.Cell>
                        <Table.Cell>
                          <ModalWithFormAndMutation
                            mutation={DELETE_CONDUCTED_SURVEY}
                            modalTitle={'Delete a conducted survey'}
                            additionalVariables={{ id }}
                            refetchQueries={[
                              {
                                query: ALL_CONDUCTED_SURVEYS,
                              },
                            ]}
                            modalTrigger={props => (
                              <TextOnlyButton
                                style={{ color: 'salmon' }}
                                {...props}
                              >
                                Delete
                              </TextOnlyButton>
                            )}
                            fields={[]}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }
                )}
              </Table.Body>
            </Table>
          )
        }}
      </Query>
    </Sheet>
  )
}

export default Index
