import React from 'react'

import AddButton, { AddWrapper } from '../../../common/AddButton'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'
import { CREATE_USER, CREATE_TEAM } from '../../../graphql/mutations'
import { ORGANIZATION } from '../../../graphql/queries'

import { teamFields } from './TeamSection'
import { userFormFields } from './UserSectionRow'

const TeamAndUserAdditionButtons = ({ id }) => {
  return (
    <AddWrapper>
      <ModalWithFormAndMutation
        mutation={CREATE_TEAM}
        modalTitle="Create new team"
        additionalVariables={{ organizationId: id }}
        refetchQueries={[
          {
            query: ORGANIZATION,
            variables: { id },
          },
        ]}
        modalTrigger={props => (
          <AddButton style={{ marginRight: 15 }} {...props}>
            New team
          </AddButton>
        )}
        fields={teamFields()}
      />
      <ModalWithFormAndMutation
        mutation={CREATE_USER}
        modalTitle="Create user"
        additionalVariables={{ organizationId: id }}
        refetchQueries={[
          {
            query: ORGANIZATION,
            variables: { id },
          },
        ]}
        modalTrigger={props => (
          <AddButton style={{ marginRight: 15 }} {...props}>
            New user
          </AddButton>
        )}
        fields={userFormFields()}
      />
    </AddWrapper>
  )
}

export default TeamAndUserAdditionButtons
