import React from 'react'

import ControlledInput from './components/form-elements/ControlledInput'
import ControlledJsonInput from './components/form-elements/ControlledJsonInput'
import ControlledJsonTextarea from './components/form-elements/ControlledJsonTextarea'
import ControlledSelectInput from './components/form-elements/ControlledSelectInput'
import ControlledTextarea from './components/form-elements/ControlledTextarea'

const renderField = (
  { inputType, value, options, ...rest },
  handleChange,
  handleJsonChange,
  state
) => {
  const key = Object.keys(value)[0]
  if (inputType === 'jsonTextInput') {
    return (
      <ControlledJsonInput
        handleJsonChange={handleJsonChange}
        objName={key}
        values={state[key]}
        {...rest}
      />
    )
  }
  if (inputType === 'jsonTextarea') {
    return (
      <ControlledJsonTextarea
        handleJsonChange={handleJsonChange}
        objName={key}
        values={state[key]}
        {...rest}
      />
    )
  }
  if (inputType === 'textarea') {
    return (
      <ControlledTextarea
        name={key}
        value={state[key]}
        onChange={handleChange}
        {...rest}
      />
    )
  }
  if (['text', 'password'].includes(inputType)) {
    return (
      <ControlledInput
        inputType={inputType}
        name={key}
        value={state[key]}
        onChange={handleChange}
        {...rest}
      />
    )
  }
  if (inputType === 'selectInput') {
    return (
      <ControlledSelectInput
        name={key}
        selectedOption={state[key]}
        options={options}
        controlFunc={handleChange}
        {...rest}
      />
    )
  }
}

export default renderField
