import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'
import principleTitle from './principleTitle'
import getDifference from './getDifference'
import BarChart from './BarChart'
import renderFeedbackTextSnippets from './renderFeedbackTextSnippets'
import TextOnlyButton from '../../../../globals/components/TextOnlyButton'

const RightSide = styled.div({
  width: '50%',
  backgroundColor: '#f5fbff',
  padding: '20px 50px',
})
const RightTitle = styled.div({
  marginBottom: '15px',
  fontWeight: 600,
})
const RightBox = styled.div({
  backgroundColor: '#fff',
  border: '1px solid',
  borderColor: colors.generalGreyBorder,
  borderRadius: '2px',
  minHeight: '50px',
  padding: '8px 20px',
  marginBottom: '16px',
  lineHeight: '20px',
})
const RightBoxTitle = styled.div({
  fontWeight: 600,
})
const OverallScore = styled.span({
  display: 'flex',
  marginBottom: '30px',
})
const Difference = styled.div({})

const differenceInWords = ({ difference, positive, t }) => {
  if (difference === 0) {
    return t('report.onParWith')
  }
  if (positive) {
    return t('report.over', { difference })
  }
  return t('report.below', { difference })
}

const RightSideTopSection = ({
  index,
  principles,
  locale,
  resultTotalSum,
  resultPrinciples,
  normPrinciples,
  normTotalValue,
  maxSurveyPoints,
  filteredContentfulData,
  showLongFeedback,
  handleFeedbackClick,
  t,
}) => {
  let points = resultTotalSum
  let normValue = normTotalValue
  let titleDisplay = t('report.eightPrinciplesFor')
  let resultDisplay = `${points} / ${principles.length * 100}`
  const isPrincipleSelected = index !== undefined
  if (isPrincipleSelected) {
    titleDisplay = principleTitle({
      principles,
      index,
      locale,
    })
    resultDisplay = `${resultPrinciples[index].totalAverage} / 100`
    points = resultPrinciples[index].totalAverage
    normValue = normPrinciples[index].averageNormValue
  }
  const { feedback, ingress } = renderFeedbackTextSnippets({
    points,
    activeSlice: index,
    resultPrinciples,
    filteredContentfulData,
  })
  const [difference, positive] = getDifference({
    points,
    normValue,
  })
  const differenceDisplay = differenceInWords({ difference, positive, t })
  return (
    <RightSide>
      <RightTitle>{titleDisplay}</RightTitle>
      <RightBox>
        <RightBoxTitle>
          <OverallScore>
            {t('report.resultRightSide')}
            <span style={{ color: colors.defaultBlue, marginLeft: 5 }}>
              {resultDisplay}
            </span>
          </OverallScore>
          <Difference>
            <span style={{ color: colors.defaultBlue, marginRight: 5 }}>
              {differenceDisplay}
            </span>
            {t('report.index')}
          </Difference>
          <BarChart
            {...{ isPrincipleSelected, points, normValue, maxSurveyPoints }}
          />
        </RightBoxTitle>
      </RightBox>
      <RightBox>
        <div style={{ marginRight: 5, fontWeight: 600 }}>{ingress}</div>
        {!showLongFeedback ? (
          <TextOnlyButton onClick={handleFeedbackClick}>
            {t('report.seeFullFeedback')}
          </TextOnlyButton>
        ) : (
          <span>{feedback}</span>
        )}
      </RightBox>
    </RightSide>
  )
}

export default RightSideTopSection
