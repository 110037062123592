import { gql } from 'apollo-boost'

import { fragments, secondaryFragments } from './fragments'

export const DELETE_CONDUCTED_SURVEY = gql`
  mutation($id: ID!) {
    deleteConductedSurvey(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const UPDATE_USER = gql`
  mutation(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $password: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      ...CompleteUser
    }
  }
  ${fragments.user}
`
export const END_RESPONDENT = gql`
  mutation(
    $conductedSurveyIdentifier: String!
    $respondentIdentifier: String!
    $isFinished: Boolean!
  ) {
    updateRespondent(
      conductedSurveyIdentifier: $conductedSurveyIdentifier
      respondentIdentifier: $respondentIdentifier
      isFinished: $isFinished
    ) {
      id
      isFinished
      identifier
    }
  }
`
export const DELETE_TEAM_ACCESS = gql`
  mutation($teamId: ID!, $userId: ID!) {
    deleteTeamAccess(teamId: $teamId, userId: $userId) {
      ...CompleteTeam
    }
  }
  ${secondaryFragments.team}
`
export const SET_DEFAULT_TEAM = gql`
  mutation($teamId: ID!, $userId: ID!) {
    setDefaultTeam(teamId: $teamId, userId: $userId) {
      token
    }
  }
`
export const UPDATE_TEAM_ACCESS = gql`
  mutation($teamId: ID!, $userId: ID!, $role: String, $isActive: Boolean) {
    updateTeamAccess(
      teamId: $teamId
      userId: $userId
      role: $role
      isActive: $isActive
    ) {
      ...CompleteTeam
      users {
        ...UserWithTeamAccess
      }
    }
  }
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
`
export const CREATE_TEAM_ACCESS = gql`
  mutation($teamId: ID!, $userId: ID!, $role: String) {
    createTeamAccess(teamId: $teamId, userId: $userId, role: $role) {
      ...CompleteTeam
      users {
        ...UserWithTeamAccess
      }
    }
  }
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
`
export const END_CONDUCTED_SURVEY = gql`
  mutation($id: ID!) {
    endConductedSurvey(id: $id) {
      ...CompleteConductedSurvey
    }
  }
  ${fragments.conductedSurvey}
`
export const UPDATE_RESPONSE = gql`
  mutation(
    $value: Int!
    $id: ID!
    $conductedSurveyIdentifier: String!
    $respondentIdentifier: String!
  ) {
    updateResponse(
      id: $id
      value: $value
      conductedSurveyIdentifier: $conductedSurveyIdentifier
      respondentIdentifier: $respondentIdentifier
    ) {
      id
      value
      question {
        id
      }
    }
  }
`
export const CREATE_RESPONSE = gql`
  mutation(
    $value: Int!
    $questionId: ID!
    $conductedSurveyIdentifier: String!
    $respondentIdentifier: String!
  ) {
    createResponse(
      value: $value
      questionId: $questionId
      conductedSurveyIdentifier: $conductedSurveyIdentifier
      respondentIdentifier: $respondentIdentifier
    ) {
      id
      value
      question {
        id
      }
    }
  }
`
export const CREATE_CONDUCTED_SURVEY = gql`
  mutation(
    $teamId: ID!
    $launchedById: ID!
    $message: String
    $includeAdmins: Boolean
  ) {
    createConductedSurvey(
      teamId: $teamId
      launchedById: $launchedById
      message: $message
      includeAdmins: $includeAdmins
    ) {
      ...CompleteConductedSurvey
    }
  }
  ${fragments.conductedSurvey}
`
