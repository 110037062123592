import React from 'react'

import { UPDATE_TEAM, DELETE_TEAM } from '../../../graphql/mutations'
import { CREATE_TEAM_ACCESS } from '../../../../graphql/mutations'
import { ORGANIZATION } from '../../../graphql/queries'

import arrayOfTrueFalseSelectOptions from '../../../common/arrayOfTrueFalseSelectOptions'
import renderOrgUsersAsSelectOptions from '../../../../globals/renderOrgUsersAsSelectOptions'
import Sheet from '../../../common/Sheet'
import { MetaInfo, MetaKey, MetaWrapper, Divider } from './Meta'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'
import { AddButtonOnlyText } from '../../../common/AddButton'
import TeamUsers from './TeamUsers'
import ConductedSurveys from './ConductedSurveys'

export const teamFields = (name, isActivated) => [
  {
    inputType: 'text',
    value: { name: name || '' },
  },
  {
    inputType: 'selectInput',
    value: { isActivated: isActivated || false },
    options: arrayOfTrueFalseSelectOptions,
  },
]

export const teamAccessFields = ({
  role = '',
  userId = '',
  usersAsSelectOptions,
} = {}) => {
  return [
    {
      inputType: 'selectInput',
      value: { role: role || 'member' },
      options: [
        { value: 'admin', text: 'Admin' },
        { value: 'member', text: 'Member' },
      ],
    },
    {
      inputType: 'selectInput',
      value: { userId },
      options: usersAsSelectOptions,
      placeholder: 'Choose user',
    },
  ]
}

const TeamSection = ({
  organizationId,
  team: { id, name, isActivated, users: teamUsers, conductedSurveys },
  orgUsers,
}) => {
  const usersAsSelectOptions = renderOrgUsersAsSelectOptions({
    orgUsers,
    teamUsers,
  })
  return (
    <Sheet
      data-testid={'team'}
      title={`Team: ${name}`}
      meta={
        <MetaWrapper>
          <ModalWithFormAndMutation
            mutation={UPDATE_TEAM}
            modalTitle={`Edit ${name}`}
            additionalVariables={{ id }}
            fields={teamFields(name, isActivated)}
            modalTrigger={props => (
              <AddButtonOnlyText {...props}>Edit team</AddButtonOnlyText>
            )}
            bottomRight={
              !teamUsers.length && {
                refetchQueries: {
                  query: ORGANIZATION,
                  variables: { id: organizationId },
                },
                mutationObj: {
                  mutation: DELETE_TEAM,
                  id,
                },
              }
            }
          />
          <Divider>|</Divider>
          <MetaInfo>
            <MetaKey>ID:</MetaKey>
            <div>{id}</div>
          </MetaInfo>
          <Divider>|</Divider>
          <MetaInfo>
            <MetaKey>Is active:</MetaKey>
            <div>{isActivated.toString()}</div>
          </MetaInfo>
        </MetaWrapper>
      }
      topRight={
        <ModalWithFormAndMutation
          mutation={CREATE_TEAM_ACCESS}
          modalTitle={`Add user to team ${name}`}
          additionalVariables={{ teamId: id }}
          modalTrigger={props => (
            <AddButtonOnlyText {...props}>Add user to team</AddButtonOnlyText>
          )}
          fields={teamAccessFields({ usersAsSelectOptions })}
        />
      }
    >
      <div style={{ fontSize: '20px', marginTop: '10px' }}>
        Conducted Surveys
      </div>
      <ConductedSurveys
        items={conductedSurveys}
        organizationId={organizationId}
        teamId={id}
      />
      <div style={{ fontSize: '20px', marginTop: '30px' }}>Members</div>
      <TeamUsers items={teamUsers} id={id} organizationId={organizationId} />
    </Sheet>
  )
}

export default TeamSection
