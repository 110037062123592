import { gql } from 'apollo-boost'

// Fragments
const fragments = {
  survey: gql`
    fragment CompleteSurvey on Survey {
      id
      title
      version
    }
  `,
  principle: gql`
    fragment CompletePrinciple on Principle {
      id
      publicTitle
      internalTitle
      description
      position
      imageUrl
      survey {
        id
      }
    }
  `,
  subScale: gql`
    fragment CompleteSubScale on SubScale {
      id
      internalTitle
      references
      position
      deviationValue
      normValue
    }
  `,
  question: gql`
    fragment CompleteQuestion on Question {
      id
      text
      helpText
      position
      scaleEnds
      scaleWidth
      isReversed
    }
  `,
}

// Queries
export const SURVEYS = gql`
  query {
    surveys {
      ...CompleteSurvey
      principles {
        ...CompletePrinciple
      }
      organizations {
        id
      }
    }
  }
  ${fragments.principle}
  ${fragments.survey}
`
export const SURVEY = gql`
  query($id: ID!) {
    survey(id: $id) {
      conductedSurveys {
        id
      }
      ...CompleteSurvey
      principles {
        ...CompletePrinciple
        subScales {
          ...CompleteSubScale
          questions {
            ...CompleteQuestion
          }
        }
      }
    }
  }
  ${fragments.survey}
  ${fragments.principle}
  ${fragments.subScale}
  ${fragments.question}
`

// Mutations
export const DUPLICATE_SURVEY = gql`
  mutation($id: ID!) {
    duplicateSurvey(id: $id) {
      ...CompleteSurvey
    }
  }
  ${fragments.survey}
`
export const UPDATE_SURVEY = gql`
  mutation($id: ID!, $title: String!, $version: String!) {
    updateSurvey(id: $id, title: $title, version: $version) {
      ...CompleteSurvey
    }
  }
  ${fragments.survey}
`
export const DELETE_SURVEY = gql`
  mutation($id: ID!) {
    deleteSurvey(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const UPDATE_QUESTION = gql`
  mutation(
    $id: ID!
    $text: JSON!
    $helpText: JSON
    $scaleEnds: String!
    $scaleWidth: String!
    $isReversed: Boolean!
  ) {
    updateQuestion(
      id: $id
      text: $text
      helpText: $helpText
      scaleEnds: $scaleEnds
      scaleWidth: $scaleWidth
      isReversed: $isReversed
    ) {
      ...CompleteQuestion
    }
  }
  ${fragments.question}
`
export const DELETE_QUESTION = gql`
  mutation($id: ID!) {
    deleteQuestion(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const CREATE_QUESTION = gql`
  mutation($subScaleId: ID!) {
    createQuestion(subScaleId: $subScaleId) {
      ...CompleteQuestion
    }
  }
  ${fragments.question}
`
export const INSERT_QUESTION_AT = gql`
  mutation($id: ID!, $parentId: ID!, $newPosition: Int!, $direction: String!) {
    insertQuestionAt(
      id: $id
      subScaleId: $parentId
      newPosition: $newPosition
      direction: $direction
    ) {
      ...CompleteSubScale
    }
  }
  ${fragments.subScale}
`
export const UPDATE_SUBSCALE = gql`
  mutation(
    $id: ID!
    $internalTitle: String
    $references: String
    $deviationValue: Int
    $normValue: Int
  ) {
    updateSubScale(
      id: $id
      internalTitle: $internalTitle
      references: $references
      deviationValue: $deviationValue
      normValue: $normValue
    ) {
      ...CompleteSubScale
    }
  }
  ${fragments.subScale}
`
export const DELETE_SUBSCALE = gql`
  mutation($id: ID!) {
    deleteSubScale(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const CREATE_SUBSCALE = gql`
  mutation($principleId: ID!) {
    createSubScale(principleId: $principleId) {
      ...CompleteSubScale
    }
  }
  ${fragments.subScale}
`
export const INSERT_SUBSCALE_AT = gql`
  mutation($id: ID!, $parentId: ID!, $newPosition: Int!, $direction: String!) {
    insertSubScaleAt(
      id: $id
      principleId: $parentId
      newPosition: $newPosition
      direction: $direction
    ) {
      ...CompletePrinciple
    }
  }
  ${fragments.principle}
`
export const UPDATE_PRINCIPLE = gql`
  mutation(
    $id: ID!
    $internalTitle: String
    $publicTitle: JSON
    $description: JSON
    $imageUrl: String
    $position: Int
  ) {
    updatePrinciple(
      id: $id
      internalTitle: $internalTitle
      publicTitle: $publicTitle
      description: $description
      imageUrl: $imageUrl
      position: $position
    ) {
      ...CompletePrinciple
    }
  }
  ${fragments.principle}
`
export const DELETE_PRINCIPLE = gql`
  mutation($id: ID!) {
    deletePrinciple(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const CREATE_PRINCIPLE = gql`
  mutation($surveyId: ID!) {
    createPrinciple(surveyId: $surveyId) {
      ...CompletePrinciple
    }
  }
  ${fragments.principle}
`
export const INSERT_PRINCIPLE_AT = gql`
  mutation($id: ID!, $parentId: ID!, $newPosition: Int!, $direction: String!) {
    insertPrincipleAt(
      id: $id
      surveyId: $parentId
      newPosition: $newPosition
      direction: $direction
    ) {
      ...CompleteSurvey
    }
  }
  ${fragments.survey}
`
