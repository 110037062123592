import React from 'react'
import { Table } from 'semantic-ui-react'

import Sheet from '../../../common/Sheet'
import multipleSort from '../../../../globals/multipleSort'
import { MetaInfo, MetaKey } from './Meta'
import UserSectionRow from './UserSectionRow'

const UserSection = ({ users, organizationId }) => {
  return (
    <Sheet title="All users" data-testid={'allUsers'}>
      <MetaInfo>
        <MetaKey>User count:</MetaKey>
        <div>{users.length}</div>
      </MetaInfo>
      <Table celled fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Default team</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.sort(multipleSort(['firstName'])).map(user => (
            <UserSectionRow
              key={user.id}
              user={user}
              organizationId={organizationId}
            />
          ))}
        </Table.Body>
      </Table>
    </Sheet>
  )
}

export default UserSection
