export default {
  yesNo: {
    en: {
      low: 'No, not at all',
      high: 'Yes, completely',
    },
    sv: {
      low: 'Net, inte alls',
      high: 'Ja, stämmer helt',
    },
  },
  completion: {
    en: {
      low: 'Not at all',
      high: 'Completely',
    },
    sv: {
      low: 'Inte alls',
      high: 'Helt och hållet',
    },
  },
  degree: {
    en: {
      low: 'To a very small extent',
      high: 'To a very large extent',
    },
    sv: {
      low: 'I mycket låg grad',
      high: 'I mycket hög grad',
    },
  },
  frequency: {
    en: {
      low: 'Hardly ever',
      high: 'Always',
    },
    sv: {
      low: 'Nästan aldrig',
      high: 'Alltid',
    },
  },
  correctness: {
    en: {
      low: 'Not true at all',
      high: 'Completely true',
    },
    sv: {
      low: 'Stämmer inte alls',
      high: 'Stämmer helt',
    },
  },
  agreement: {
    en: {
      low: 'Strongly disagree',
      high: 'Strongly agree',
    },
    sv: {
      low: 'Håller inte med alls',
      high: 'Håller helt med',
    },
  },
}
