import React from 'react'
import styled from 'styled-components/macro'
import { Mutation } from 'react-apollo'
import { withAlert } from 'react-alert'

import Button from './Button'

const DirectActionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const Title = styled.div({
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: '600',
  borderBottom: '1px solid #eee',
  padding: '10px 0 30px',
})
const Content = styled.div({
  margin: '20px 20px 50px',
})

const handleClick = ({
  mutationFunction,
  hideModal,
  alert,
  successMsg,
  postMutationFunction,
}) => {
  mutationFunction()
    .then(({ data }) => {
      postMutationFunction && postMutationFunction()
      if (successMsg) {
        alert.success(successMsg)
      }
    })
    .catch(error =>
      alert.error(error.message.replace('GraphQL error:', ' ').trim())
    )
}

const DirectActionMutationInner = ({
  mutation,
  variables,
  modalTitle,
  contentText,
  buttonText,
  refetchQueries,
  ...postMutationProps
}) => {
  return (
    <Mutation mutation={mutation} variables={variables} {...{ refetchQueries }}>
      {(mutationFunction, { data, error, loading }) => (
        <DirectActionWrapper>
          <Title>{modalTitle}</Title>
          <Content>{contentText}</Content>
          <Button
            onClick={() =>
              handleClick({ mutationFunction, ...postMutationProps })
            }
          >
            {buttonText}
          </Button>
        </DirectActionWrapper>
      )}
    </Mutation>
  )
}

export default withAlert(DirectActionMutationInner)
