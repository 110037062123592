import React from 'react'
import { NamespacesConsumer } from 'react-i18next'

import Sheet from '../../common/Sheet'
import TopSection from './bits/TopSection'
import BottomSection from './bits/BottomSection'

const getContentfulItem = ({ points, contentfulData, position }) => {
  const dpName = `DP${position + 1}`
  return contentfulData.find(
    item =>
      item.minValue <= points &&
      item.maxValue >= points &&
      item.principleName === dpName
  )
}

const Report = ({
  endedAt,
  conductedSurveyTitle,
  team,
  habitudCourse,
  comparableConductedSurveys,
  contentfulData,
  ...rest
}) => {
  const filteredContentfulData = rest.resultPrinciples
    .map(p =>
      getContentfulItem({
        points: p.totalAverage,
        contentfulData,
        position: p.position,
      })
    )
    .sort((a, b) => a.principleName.localeCompare(b.principleName))

  return (
    <NamespacesConsumer>
      {t => (
        <Sheet subPage>
          <TopSection
            {...{
              ...rest,
              endedAt,
              conductedSurveyTitle,
              team,
              filteredContentfulData,
              t,
            }}
          />
          <BottomSection
            {...{
              ...rest,
              habitudCourse,
              comparableConductedSurveys,
              t,
            }}
          />
        </Sheet>
      )}
    </NamespacesConsumer>
  )
}

export default Report
