import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { createClient } from 'contentful'

import { CONDUCTED_SURVEYS, NORM_VALUES } from '../../../graphql/queries'

import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'
import { roundOffToDecimals } from '../../../globals/utilFunctions'
import withSession from '../../../globals/withSession'
import Report from './Report'

const contentfulClient = createClient({
  space: '29wnk0q9jemm',
  accessToken:
    'fe80d24b6687551f3d34ee23b6d9cedbddf38589369225dec43e9e9c6f0b7412',
})
const renderNormValues = survey => {
  const principleAverages = survey.principles
    .sort((a, b) => a.position - b.position)
    .reduce((acc1, { id, publicTitle, internalTitle, subScales }) => {
      const sum = subScales.reduce((acc2, { normValue }) => acc2 + normValue, 0)
      const average = sum / subScales.length
      const roundedOffAverage = roundOffToDecimals({
        number: average,
        decimals: 0,
      })
      acc1.push({
        publicTitle,
        internalTitle,
        principleId: id,
        averageNormValue: roundedOffAverage,
      })
      return acc1
    }, [])
  const totalNormValue = principleAverages.reduce(
    (acc, { averageNormValue }) => acc + averageNormValue,
    0,
  )
  return {
    principleAverages,
    totalNormValue,
  }
}

class ReportContainer extends Component {
  state = {
    contentfulData: undefined,
    locale: this.props.locale,
    habitudCourses: [],
  }
  componentDidMount() {
    const { locale } = this.state
    contentfulClient
      .getEntries({
        // content_type: categoryNameSingular,
        // order: 'fields.title',
        locale: locale === 'en' ? 'en-US' : locale,
      })
      .then(data => {
        this.setState({ contentfulData: data.items.map(item => item.fields) })
      })
  }
  render() {
    const {
      match: {
        params: { id: conductedSurveyId },
      },
      team,
    } = this.props
    const { contentfulData, locale, habitudCourses } = this.state
    return (
      <Query query={CONDUCTED_SURVEYS} variables={{ teamId: team.id }}>
        {({ data, loading: loadingOne, error }) => {
          if (loadingOne) return <Preloader />
          if (error) return <Error />
          const validConductedSurveys = data.conductedSurveys.filter(
            item => item.result && item.result.valid,
          )
          const conductedSurvey = validConductedSurveys.find(
            item => item.id === conductedSurveyId,
          )
          const comparableConductedSurveys = validConductedSurveys.filter(
            item => item.id !== conductedSurveyId,
          )
          if (conductedSurvey.isActive && !conductedSurvey.result.valid) {
            return <div>No valid report.</div>
          }
          return (
            <Query query={NORM_VALUES}>
              {({ data, loading: loadingTwo, error }) => {
                if (loadingTwo || !contentfulData) return <Preloader />
                if (error) return <Error />
                const { survey } = data.team
                const {
                  principleAverages: normPrinciples,
                  totalNormValue: normTotalValue,
                } = renderNormValues(survey)
                const {
                  result,
                  title: conductedSurveyTitle,
                  endedAt,
                } = conductedSurvey
                const {
                  principles: resultPrinciples,
                  totalSum: resultTotalSum,
                } = result
                return (
                  <Report
                    {...{
                      endedAt,
                      conductedSurveyTitle,
                      normPrinciples,
                      normTotalValue,
                      team,
                      locale,
                      principles: survey.principles,
                      contentfulData,
                      resultPrinciples,
                      resultTotalSum,
                      habitudCourses,
                      comparableConductedSurveys,
                    }}
                  />
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}
export default withSession(ReportContainer)
