import React, { Component } from 'react'

class FlashMessage extends Component {
  state = { visible: false }

  componentWillMount() {
    this.setState({ visible: true })
  }
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ visible: false })
    }, 3000)
  }

  componentWillUnMount() {
    clearTimeout(this.timer)
  }

  render() {
    if (this.state.visible) {
      return <div style={this.props.style}>Saved!</div>
    }
    return null
  }
}

export default FlashMessage
