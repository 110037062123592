import { Mutation } from 'react-apollo'
import { NamespacesConsumer } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { withAlert } from 'react-alert'
import React from 'react'
import styled from 'styled-components/macro'

import AuthSheet from './AuthSheet'
import AutoSignin from './AutoSignin'
import Button from '../team-admin/common/Button'
import ControlledInput from '../globals/components/form-elements/ControlledInput'

const SIGNIN_USER = gql`
  mutation($email: String!, $password: String!) {
    signinUser(email: $email, password: $password) {
      token
    }
  }
`
const initialState = {
  email: '',
  password: '',
}

const ForgotPwd = styled.div({
  margin: 10,
})

class Signin extends React.Component {
  state = { ...initialState }

  clearState = () => {
    this.setState({ ...initialState })
  }
  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  handleSubmit = (event, signinUser) => {
    event.preventDefault()
    signinUser()
      .then(async ({ data }) => {
        const {
          signinUser: { token },
        } = data
        localStorage.setItem('token', token)
        await this.props.refetch()
        // this.clearState()
        this.props.history.push('/')
      })
      .catch(e => {
        this.props.alert.error(e.message.replace('GraphQL error:', ' ').trim())
      })
  }
  validateForm = () => {
    const { email, password } = this.state
    const isInvalid = !email || !password
    return isInvalid
  }
  render() {
    const { email, password } = this.state
    return (
      <NamespacesConsumer>
        {t => (
          <AuthSheet title={t('signin.mainTitle')}>
            <AutoSignin t={t} />
            <Mutation mutation={SIGNIN_USER} variables={{ email, password }}>
              {(signinUser, { data, loading, error }) => {
                return (
                  <form
                    className="form"
                    onSubmit={event => this.handleSubmit(event, signinUser)}
                  >
                    <ControlledInput
                      inputType="text"
                      name="email"
                      placeholder={t('formLabels.email')}
                      value={email}
                      onChange={this.handleChange}
                    />
                    <ControlledInput
                      inputType="password"
                      name="password"
                      placeholder={t('formLabels.password')}
                      value={password}
                      onChange={this.handleChange}
                    />
                    <Button
                      isFullWidth
                      type="submit"
                      disabled={loading || this.validateForm()}
                    >
                      {t('signin.button')}
                    </Button>
                    {/* {error && <Error error={error} />} */}
                  </form>
                )
              }}
            </Mutation>
            <ForgotPwd>
              <NavLink
                to={`/forgot-password?email=${encodeURIComponent(email)}`}
              >
                {t('signin.forgotPassword')}
              </NavLink>
            </ForgotPwd>
          </AuthSheet>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withAlert(withRouter(Signin))
