import React, { Component } from 'react'
import { withAlert } from 'react-alert'
import { Mutation } from 'react-apollo'
import { NamespacesConsumer } from 'react-i18next'

import Button from './Button'
import renderField from '../../globals/renderField'
import FieldGroup from './FieldGroup'

class FormConstructor extends Component {
  state = {
    ...this.props.fields.reduce((current, next) => {
      return { ...current, ...next.value }
    }, {}),
  }
  handleJsonChange = (objName, locale, { target: { value } }) => {
    this.setState(prevState => ({
      [objName]: {
        ...prevState[objName],
        [locale]: value,
      },
    }))
  }
  handleChange = event => {
    let { name, value } = event.target
    if (['false', 'true'].includes(value)) {
      value = JSON.parse(value)
    }
    this.setState({ [name]: value })
  }
  handleSubmit = (event, mutationFunction) => {
    const { alert, successMsg, postMutationFunction } = this.props
    event.preventDefault()
    mutationFunction()
      .then(data => {
        postMutationFunction && postMutationFunction()
        if (successMsg) {
          alert.success(successMsg)
        }
      })
      .catch(error => {
        alert.error(error.message.replace('GraphQL error:', ' ').trim())
      })
  }
  render() {
    const {
      buttonText,
      mutation,
      additionalVariables,
      fields,
      isLabelHidden,
    } = this.props
    return (
      <NamespacesConsumer>
        {t => (
          <Mutation
            mutation={mutation}
            variables={{ ...this.state, ...additionalVariables }}
          >
            {(mutationFunction, { error, loading }) => (
              <form
                onSubmit={event => this.handleSubmit(event, mutationFunction)}
              >
                {fields.map(item => (
                  <FieldGroup
                    isLabelHidden={isLabelHidden}
                    key={Object.keys(item.value)[0]}
                    label={t(`formLabels.${Object.keys(item.value)[0]}`)}
                    input={renderField(
                      {
                        ...item,
                        ...(item.dataTestid && {
                          'data-testid': item.dataTestid,
                        }),
                      },
                      this.handleChange,
                      this.handleJsonChange,
                      this.state
                    )}
                  />
                ))}
                <Button>{buttonText}</Button>
              </form>
            )}
          </Mutation>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withAlert(FormConstructor)
