import React from 'react'
import styled, { css } from 'styled-components/macro'

import TransparentButton from '../../globals/components/TransparentButton'
import { colors } from '../../globals/variables'
import AddIcon from '../svgs/Add'

const BasicStyling = css({
  color: colors.linkBlue,
  backgroundColor: '#fff',
  fontSize: '13px',
  fontWeight: '600',
})

const StyledAddButton = styled(TransparentButton)(
  {
    boxShadow: '0 2px 5px 0 #33334f1a',
    display: 'flex',
    borderRadius: '2px',
    padding: '5px 7px',
    alignItems: 'center',
  },
  ...BasicStyling
)

const StyledButtonOnlyText = styled(TransparentButton)({}, ...BasicStyling)

const Wrapper = styled.div({
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
})

const AddButton = ({ children, ...rest }) => (
  <StyledAddButton {...rest}>
    <AddIcon style={{ marginRight: 5 }} /> {children}
  </StyledAddButton>
)

export const AddButtonOnlyText = ({ children, ...rest }) => (
  <StyledButtonOnlyText {...rest}>{children}</StyledButtonOnlyText>
)

export const AddWrapper = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default AddButton
