import React, { Component } from 'react'
import { Mutation } from 'react-apollo'

import ModalWithForm from './ModalWithForm'

class ModalWithFormAndMutation extends Component {
  state = {
    ...this.props.fields.reduce((current, next) => {
      return { ...current, ...next.value }
    }, {}),
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return nextProps.visible !== undefined ? { visible: nextProps.visible } : {}
  // }
  resetFormState = () => {
    this.props.fields.forEach(({ value }) => {
      this.setState({ ...value })
    })
  }
  handleJsonChange = (objName, locale, { target: { value } }) => {
    this.setState(prevState => ({
      [objName]: {
        ...prevState[objName],
        [locale]: value,
      },
    }))
  }
  handleChange = event => {
    let { name, value } = event.target
    if (['false', 'true'].includes(value)) {
      value = JSON.parse(value)
    }
    this.setState({ [name]: value })
  }
  handleSubmit = (event, mutationFunction) => {
    event.preventDefault()
    mutationFunction().then(({ data }) => {
      // console.log('TCL: ModalWithFormAndMutation -> handleSubmit -> data', data)
    })
  }
  updateCache = (cache, data) => {}
  render() {
    const {
      mutation,
      additionalVariables,
      refetchQueries,
      ...props
    } = this.props
    return (
      <Mutation
        mutation={mutation}
        variables={{ ...this.state, ...additionalVariables }}
        {...{ refetchQueries }}
        update={this.updateCache}
      >
        {(mutationFunction, { data, error }) => (
          <ModalWithForm
            {...{
              data,
              error,
              mutationFunction,
            }}
            {...props}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            handleJsonChange={this.handleJsonChange}
            state={this.state}
            resetFormState={this.resetFormState}
          />
        )}
      </Mutation>
    )
  }
}

export default ModalWithFormAndMutation
