import React from 'react'
import styled from 'styled-components/macro'

import Section from './Section'

const Respondent = styled.div({
  display: 'flex',
  marginBottom: '2px',
})
const Respondents = styled.div({})
const Name = styled.div({
  minWidth: '400px',
})
const CopyButton = styled.button({
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
})

function copyText(textToCopy) {
  const textarea = document.createElement('textarea')
  textarea.value = textToCopy
  textarea.setAttribute('readonly', '')
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  textarea.remove()
}

const renderRespondents = ({
  id,
  firstName,
  lastName,
  email,
  identifier,
  conductedSurveyIdentifier,
  isFinished,
}) => (
  <Respondent key={id}>
    <Name>
      {firstName} {lastName}, {email}
    </Name>
    {conductedSurveyIdentifier &&
      !isFinished && (
        <div>
          <CopyButton
            title="Copy unique link"
            onClick={() =>
              copyText(
                `${window.location.protocol}//${
                  window.location.hostname
                }/survey/${conductedSurveyIdentifier}/${identifier}/0`
              )
            }
            style={{
              background: 'none',
            }}
          >
            <svg style={{ width: 18, height: 18 }} viewBox="0 0 24 24">
              <path
                fill="#ccc"
                d="M17,9H7V7H17M17,13H7V11H17M14,17H7V15H14M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z"
              />
            </svg>
          </CopyButton>
        </div>
      )}
  </Respondent>
)

const RespondentListSection = ({ respondents, title }) => {
  return (
    <Section title={`2. ${title}`} style={{ marginBottom: 10 }}>
      <Respondents>{respondents.map(renderRespondents)}</Respondents>
    </Section>
  )
}

export default RespondentListSection
