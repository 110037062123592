import axios from 'axios'

const PRINCIPLE_TAGS = [
  'Princip 1: Tydlig grupp med tydligt mål',
  'Princip 2: Balans mellan insats och belöning',
  'Princip 3: Rättvist och inkluderande beslutsfattande',
  'Princip 4: Uppföljning av överenskomna beteenden',
  'Princip 5: Graderade sanktioner för ohjälpsamma beteenden',
  'Princip 6: Snabb och rättvis konflikthantering',
  'Princip 7: Mandat till självbestämmande',
  'Princip 8: Koordinering med andra grupper',
]

const URLS = PRINCIPLE_TAGS.map(
  p => `https://www.habitud.se/v1/tags/${encodeURIComponent(p)}`
)

export { PRINCIPLE_TAGS }

export default async function getHabitudCoursesMetaData() {
  return Promise.all(
    URLS.map(async (url, index) => {
      let response
      try {
        response = await axios.get(url)
      } catch (e) {
        console.error(e)
        return []
      }
      const { data } = response
      return data.slice(0, 2).map(item => ({
        hCPrinciple: `dp${index + 1}`,
        hCTitle: item.title,
        hCDescription: item.plain_description,
        hCImage: item.og_image_url,
        hCUrl: `https://www.habitud.se/courses/${item.slug || item.id}`,
        hCSubject: item.subject.title,
        hCChallengesCount: item.challenges_count,
      }))
    })
  )
}
