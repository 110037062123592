import React from 'react'
import styled from 'styled-components/macro'

import TransparentButton from './TransparentButton'

const Wrapper = styled.div(({ success, error }) => ({
  fontSize: '14px',
  padding: '10px 20px',
  color: '#383d41',
  backgroundColor: '#e2e3e5',
  borderColor: '#d6d8db',
  display: 'flex',
  alignItems: 'center',
  minWidth: '300px',
  boxSizing: 'border-box',
  position: 'absolute',
  left: 'calc(50% - 150px)',
  top: '85px',
  ...(success && {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
  }),
  ...(error && {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
  }),
}))

const ReactAlertTemplate = ({ style, options, message, close }) => {
  return (
    <Wrapper
      info={options.type === 'info'}
      success={options.type === 'success'}
      error={options.type === 'error'}
      style={style}
    >
      {message}
      <TransparentButton
        style={{
          marginLeft: 'auto',
          fontSize: '25px',
          color: 'inherit',
          fontWeight: '300',
        }}
        onClick={close}
      >
        &times;
      </TransparentButton>
    </Wrapper>
  )
}

export default ReactAlertTemplate
