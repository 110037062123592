import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'

import { TEAM } from '../graphql/queries'

import { Error, Preloader } from '../globals/components/ErrorAndPreloader'
import ReportContainer from '../team-admin/screens/report/ReportContainer'
import OrganizationsContainer from '../super-admin/screens/organizations/OrganizationsContainer'
import OrganizationContainer from '../super-admin/screens/organization/OrganizationContainer'
import SurveyConstructors from '../super-admin/screens/survey-constructors/SurveyConstructors'
import ConductedSurveys from '../super-admin/screens/conducted-surveys'
import SurveyConstructorContainer from '../super-admin/screens/survey-constructor/SurveyConstructorContainer'
import VerticalNavigation from '../super-admin/layout/VerticalNavigation'
import TextOnlyButton from '../globals/components/TextOnlyButton'
import ArrowLeft from '../team-admin/common/svgs/ArrowLeft'

const PageWrapper = styled.div({
  display: 'flex',
})
const Content = styled.div({
  minHeight: '100vh',
  backgroundColor: '#f0f2f5',
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
  paddingBottom: '100px',
  paddingLeft: '256px',
})
const ContentInner = styled.div({
  margin: '24px 24px 0px',
})

const SuperAdminRoutes = () => {
  return (
    <PageWrapper className="sa">
      <VerticalNavigation />
      <Content>
        <ContentInner>
          <Switch>
            <Route
              path="/sa/organizations"
              exact
              component={OrganizationsContainer}
            />
            <Route
              path="/sa/organizations/:organizationId/team/:teamId/reports/:id"
              render={props => {
                const { organizationId } = props.match.params
                return (
                  <Query query={TEAM}>
                    {({ data, error, loading }) => {
                      if (error) return <Error />
                      if (loading) return <Preloader />
                      return (
                        <>
                          <TextOnlyButton
                            as={Link}
                            to={`/sa/organizations/${organizationId}`}
                          >
                            <ArrowLeft />
                          </TextOnlyButton>
                          <ReportContainer
                            {...props}
                            team={data.team}
                            locale={'en'}
                          />
                        </>
                      )
                    }}
                  </Query>
                )
              }}
            />
            <Route
              path="/sa/organizations/:id"
              render={() => <OrganizationContainer />}
            />
            <Route
              exact
              path="/sa/survey-constructor"
              component={SurveyConstructors}
            />
            <Route
              path="/sa/survey-constructor/new"
              component={SurveyConstructorContainer}
            />
            <Route
              path="/sa/survey-constructor/:id"
              component={SurveyConstructorContainer}
            />
            <Route path="/sa/conducted-surveys" component={ConductedSurveys} />
            <Redirect to="/sa/organizations" />
          </Switch>
        </ContentInner>
      </Content>
    </PageWrapper>
  )
}

export default SuperAdminRoutes
