import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../globals/variables'

const Field = styled.div({
  marginBottom: '10px',
})
const Label = styled.div({
  fontWeight: 600,
  color: colors.generalFontGrey,
  marginBottom: '3px',
})

const FieldGroup = ({ label, input, isLabelHidden, ...rest }) => {
  return (
    <Field {...rest}>
      {!isLabelHidden && <Label>{label}</Label>}
      {input}
    </Field>
  )
}

export default FieldGroup
