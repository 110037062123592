import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { Provider as ReactAlertProvider } from 'react-alert'
import ReactAlertTemplate from './globals/components/ReactAlertTemplate'
import { I18nextProvider } from 'react-i18next'
import { Helmet } from 'react-helmet'
import 'moment/locale/sv'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'

import GlobalStyles from './globals/GlobalStyles'
import i18n from './globals/i18n'

import SigninRoutes from './routing/SigninRoutes'
import TeamAdminRoutes from './routing/TeamAdminRoutes'
import SuperAdminRoutes from './routing/SuperAdminRoutes'
import SurveyConductionRoutes from './routing/SurveyConductionRoutes'
import withSession from './globals/withSession'
import kickOut from './globals/kickOut'

const reactAlertOptions = {
  timeout: 5000,
  position: 'top center',
}

const defaultState = {
  isEditMode: false,
}

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4444/graphql'
    : 'https://habitud2-production.herokuapp.com/graphql'

const request = operation => {
  const token = localStorage.getItem('token')
  operation.setContext({
    headers: {
      authorization: token,
    },
  })
}
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    requestLink,
    new HttpLink({
      uri,
      credentials: 'same-origin',
    }),
  ]),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      return false
    },
  }),
  uri,
  clientState: {
    defaults: defaultState,
    resolvers: {},
  },
  fetchOptions: {
    credentials: 'include',
  },
  onError: ({ networkError, graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      if (graphQLErrors[0].extensions['code'] === 'UNAUTHENTICATED') {
        kickOut()
      } else if (
        graphQLErrors[0].extensions.exception &&
        graphQLErrors[0].extensions.exception.name === 'AuthorizationError'
      ) {
        kickOut()
      }
    }
    console.log('TCL: graphQLErrors', graphQLErrors)
    console.log('TCL: networkError', networkError)
  },
})

const renderRouting = ({ refetch, currentUser }) => {
  const basePathname = window.location.pathname.split('/')[1]
  if (basePathname && basePathname === 'survey') {
    return <SurveyConductionRoutes />
  }

  if (!currentUser) {
    console.log('NOT LOGGED IN')
    return <SigninRoutes refetch={refetch} />
  } else if (currentUser.defaultTeam) {
    return <TeamAdminRoutes currentUser={currentUser} />
  } else if (currentUser.id === 1) {
    return <SuperAdminRoutes />
  } else if (!currentUser.defaultTeam) {
    return <SigninRoutes refetch={refetch} />
  }
  return null
}

const Root = ({ refetch, currentUser }) => {
  if (currentUser) {
    setTimeout(() => i18n.changeLanguage(currentUser.locale))
  }
  return (
    <I18nextProvider i18n={i18n}>
      <Helmet defaultTitle="Habitud" titleTemplate="Habitud | %s" />
      <GlobalStyles />
      <Router>{renderRouting({ refetch, currentUser })}</Router>
    </I18nextProvider>
  )
}

const RootWithSession = withSession(Root)

ReactDOM.render(
  <ReactAlertProvider template={ReactAlertTemplate} {...reactAlertOptions}>
    <ApolloProvider client={client}>
      <RootWithSession />
    </ApolloProvider>
  </ReactAlertProvider>,
  document.getElementById('root')
)
