import { createGlobalStyle } from 'styled-components/macro'
import styledNormalize from 'styled-normalize'

import './table.css'
import { colors } from './variables'
import './fonts/avenir.css'

require('typeface-source-sans-pro')

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  body {
    font-family: Avenir, 'Source Sans Pro', 'Open Sans', sans-serif;
    color: ${colors.defaultText};
    font-size: 14px;
    overflow-y: scroll;
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
    background-color: #f5f6f7;
  }
  a {
    text-decoration: none;
    :focus, :hover, :active {
      text-decoration: none;
      color: auto;
    }
  }
`
export default GlobalStyles
