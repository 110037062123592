const differenceWithPlusMinus = ({ difference, positive }) => {
  if (difference === 0) {
    return difference
  }
  if (positive) {
    return `+${difference}`
  }
  return `-${difference}`
}

export default differenceWithPlusMinus
