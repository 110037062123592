import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Mutation } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import ReactHoverObserver from 'react-hover-observer'
import { NamespacesConsumer } from 'react-i18next'

import { CREATE_RESPONSE, UPDATE_RESPONSE } from '../../graphql/mutations'
import { RESPONDENT } from '../../graphql/queries'

import { colors } from '../../globals/variables'
import Button from '../common/Button'
import scaleEndsObject from '../../globals/static-data/scaleEnds'
import scaleWidthsObject from '../../globals/static-data/scaleWidths'

const PrincipleTitle = styled.div({
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: '10px',
})
const Content = styled.div({
  display: 'flex',
  justifyContent: 'center',
})
const InnerContent = styled.div({
  width: '300px',
})
const QuestionText = styled.div({
  color: colors.defaultText,
  fontSize: '20px',
})
const ScaleEnds = styled.div({
  display: 'flex',
  marginTop: '8px',
  justifyContent: 'space-between',
  color: colors.defaultBlue,
  fontSize: '12px',
  opacity: 0.8,
})
const PostActions = styled.div({
  display: 'flex',
  marginTop: '70px',
  justifyContent: 'space-between',
})
const ButtonGroup = styled.div({
  display: 'flex',
  marginTop: '30px',
})
const SelectButton = styled.button(({ last, first, selected, isHovering }) => {
  return {
    color: colors.defaultBlue,
    borderColor: '#b3deff !important',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: '#edf8fe',
    width: '60px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    ...(first && {
      borderLeft: '1px solid',
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
    }),
    ...(last && {
      borderRight: '1px solid',
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
    }),
    ...(isHovering && {
      backgroundColor: '#b3deff',
    }),
    ...(selected && {
      color: '#fff',
      backgroundColor: colors.defaultBlue,
      borderColor: `${colors.defaultBlue} !important`,
    }),
  }
})

class Question extends Component {
  state = {
    selectedValue: this.props.responseValue,
    id: this.props.id,
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.id !== prevState.id
      ? {
          selectedValue: nextProps.responseValue,
          id: nextProps.id,
        }
      : {}
  }
  handleSelection = event => {
    this.setState({ selectedValue: Number(event.target.value) })
  }
  handleValueSubmission = (mutationFunction, handleNextPrevClick) => {
    if (this.state.selectedValue === this.props.responseValue) {
      handleNextPrevClick({ direction: 'forward' })
    } else {
      mutationFunction().then(async ({ data }) => {
        const receivedResponse = Object.values(data)[0]
        const { id, selectedValue } = this.state
        if (
          receivedResponse &&
          receivedResponse.value === selectedValue &&
          receivedResponse.question.id === id
        ) {
          await handleNextPrevClick({ direction: 'forward' })
        } else {
          console.log('Något gick snett')
        }
      })
    }
  }
  render() {
    const {
      id: questionId,
      locale,
      text,
      scaleEnds,
      scaleWidth,
      principleTitle,
      handleNextPrevClick,
      match,
      responseId,
    } = this.props
    const scaleWidthValues = scaleWidthsObject[scaleWidth]
    const scaleEndValues = scaleEndsObject[scaleEnds][locale]
    const { selectedValue } = this.state
    const {
      conductedSurveyIdentifier,
      respondentIdentifier,
      step,
    } = match.params

    return (
      <NamespacesConsumer>
        {t => (
          <Content data-testid={'question'}>
            <InnerContent>
              <PrincipleTitle>{principleTitle[locale]}</PrincipleTitle>
              <QuestionText>{text[locale]}</QuestionText>
              <ButtonGroup>
                {scaleWidthValues.map((value, index) => (
                  <ReactHoverObserver key={index}>
                    {({ isHovering }) => (
                      <SelectButton
                        isHovering={isHovering}
                        first={index === 0}
                        last={index === scaleWidthValues.length - 1}
                        onClick={this.handleSelection}
                        value={value}
                        selected={value === selectedValue}
                      >
                        {value}
                      </SelectButton>
                    )}
                  </ReactHoverObserver>
                ))}
              </ButtonGroup>
              <ScaleEnds>
                <div>{scaleEndValues.low}</div>
                <div>{scaleEndValues.high}</div>
              </ScaleEnds>
              <PostActions>
                <Button
                  data-testid={'backButton'}
                  as={Link}
                  small={1}
                  white={1}
                  to={`${Number(step) - 1}`}
                >
                  ← {t('survey.question.backButton')}
                </Button>

                {!isNaN(selectedValue) ? (
                  <Mutation
                    mutation={responseId ? UPDATE_RESPONSE : CREATE_RESPONSE}
                    variables={{
                      conductedSurveyIdentifier,
                      respondentIdentifier,
                      value: selectedValue,
                      ...(responseId
                        ? {
                            id: responseId,
                          }
                        : {
                            questionId,
                          }),
                    }}
                    refetchQueries={[
                      {
                        query: RESPONDENT,
                        variables: {
                          conductedSurveyIdentifier,
                          respondentIdentifier,
                        },
                      },
                    ]}
                  >
                    {(mutationFunction, { loading, error, data }) => (
                      <Button
                        data-testid={'nextButton'}
                        onClick={() =>
                          this.handleValueSubmission(
                            mutationFunction,
                            handleNextPrevClick
                          )
                        }
                        small
                      >
                        {t('survey.question.nextButton')} →
                      </Button>
                    )}
                  </Mutation>
                ) : null
                // <Button as={Link} small={1} white={1} to={`${Number(step) + 1}`}>
                //   Skip
                // </Button>
                }
              </PostActions>
            </InnerContent>
          </Content>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withRouter(Question)
