import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'

const Wrapper = styled.div({
  position: 'relative',
  minHeight: '27px',
  width: '200px',
  color: colors.generalFontGrey,
  fontSize: '11px',
})
const BarCanvas = styled.div(({ value }) => ({
  position: 'absolute',
  height: '5px',
  top: '4px',
  backgroundColor: '#ddd',
  left: 0,
  width: `${value}px`,
  zIndex: 10,
  borderRadius: '30px',
}))
const ResultBar = styled.div(({ value }) => ({
  position: 'absolute',
  height: '5px',
  top: '4px',
  backgroundColor: colors.defaultBlue,
  left: 0,
  width: `${value}px`,
  zIndex: 12,
  borderRadius: '30px',
}))
const NormStandingBar = styled.div(({ value }) => ({
  position: 'absolute',
  height: '14px',
  top: '0px',
  backgroundColor: '#bbb',
  left: `${value - 2}px`,
  width: `3px`,
  zIndex: 8,
  borderRadius: '30px',
}))
const minMaxValueStyling = {
  position: 'absolute',
  bottom: '0',
  opacity: '0.5',
}
const MinValue = styled.div({
  ...minMaxValueStyling,
  left: '0px',
})
const MaxValue = styled.div({
  ...minMaxValueStyling,
  right: `0`,
})

const BarChart = ({
  points,
  normValue,
  maxSurveyPoints,
  isPrincipleSelected,
}) => {
  const barLength = 200
  const maxPoints = isPrincipleSelected ? 100 : maxSurveyPoints
  const resultPercentage = points / maxPoints
  const normPercentage = normValue / maxPoints
  return (
    <Wrapper>
      <BarCanvas value={barLength} />
      <ResultBar value={barLength * resultPercentage} />
      <NormStandingBar title={normValue} value={barLength * normPercentage} />
      <MinValue>0</MinValue>
      <MaxValue value={barLength}>{maxPoints}</MaxValue>
    </Wrapper>
  )
}

export default BarChart
