import React, { Component } from 'react'
import styled from 'styled-components/macro'
import AntModal from 'antd/lib/modal'
import { Mutation } from 'react-apollo'
import '../../globals/modal-styling.css'
import Error from '../../globals/components/Error'

const Wrapper = styled.div({
  position: 'relative',
})
const Deletion = styled.div({
  position: 'absolute',
  right: 0,
  bottom: '5px',
})

class Modal extends Component {
  state = { visible: this.props.isModalVisible || false }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // do things with nextProps.someProp and prevState.cachedSomeProp
  //   return nextProps.visible !== undefined ? { visible: nextProps.visible } : {}
  // }

  handleHideModalClick = (event, mutationFunction, data) => {
    event.preventDefault()
    mutationFunction().then(({ data }) => {
      // console.log('TCL: TeamSection -> handleSubmit -> data2', data)
      this.setState({ visible: false })
    })
  }

  showModal = e => {
    e.preventDefault()
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
    this.props.resetFormState()
  }

  render() {
    const { render, title, bottomRight } = this.props
    return (
      <div style={{ display: 'flex' }}>
        {render({ onClick: this.showModal })}
        <AntModal
          title={title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Wrapper>
            {this.props.formContent}
            {bottomRight &&
              !!Object.keys(bottomRight).length && (
                <Mutation
                  mutation={bottomRight.mutationObj.mutation}
                  variables={{ id: bottomRight.mutationObj.id }}
                  refetchQueries={
                    bottomRight.hasOwnProperty('refetchQueries')
                      ? () => [bottomRight.refetchQueries]
                      : {}
                  }
                >
                  {(mutationFunction, { loading, error, data }) => (
                    <Deletion>
                      {error && <Error error={error} />}
                      <button
                        onClick={event =>
                          this.handleHideModalClick(
                            event,
                            mutationFunction,
                            data
                          )
                        }
                      >
                        Delete
                      </button>
                    </Deletion>
                  )}
                </Mutation>
              )}
          </Wrapper>
        </AntModal>
      </div>
    )
  }
}

export default Modal
