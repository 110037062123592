import styled from 'styled-components/macro'

import TransparentButton from './TransparentButton'
import { colors } from '../variables'

const TextOnlyButton = styled(TransparentButton)`
  &,
  &:hover {
    color: ${colors.linkBlue};
  }
`

export default TextOnlyButton
