import { NamespacesConsumer } from 'react-i18next'
import React from 'react'
import styled from 'styled-components/macro'

import { colors, mobileWidth } from '../../globals/variables'
import Button from '../common/Button'
import fixDropboxLink from '../common/fixDropboxLink'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    margin-left: 30px;
  }
  & > img {
    margin-right: 30px;
  }
  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
    & > div,
    & > img {
      margin: 0;
    }
  }
`
const Image = styled.img({
  width: '250px',
})
const TextContent = styled.div({
  width: '280px',
  display: 'flex',
  flexDirection: 'column',
})
const Title = styled.div({
  fontSize: '20px',
  color: colors.defaultText,
  marginBottom: '15px',
  marginTop: '20px',
})
const Description = styled.div({ marginBottom: '20px' })

const Principle = ({
  locale,
  publicTitle,
  imageUrl,
  description,
  handleNextPrevClick,
  position,
}) => {
  return (
    <NamespacesConsumer>
      {t => (
        <Content data-testid={'principle'}>
          <Image src={fixDropboxLink(imageUrl)} />
          <TextContent>
            <Title>{`${position}. ${publicTitle[locale]}`}</Title>
            <Description>{description[locale]}</Description>
            <Button
              onClick={handleNextPrevClick}
              style={{ marginTop: 'auto', textAlign: 'center' }}
            >
              {t('survey.principle.button')}
            </Button>
          </TextContent>
        </Content>
      )}
    </NamespacesConsumer>
  )
}

export default Principle
