import React from 'react'
import styled from 'styled-components/macro'

import { decamelize } from '../../../../globals/utilFunctions'
import ActionBar from './ActionBar'
import { Field, Label, Content } from './miscStyledComponentElements'

const bgColors = {
  Principle: '#008297',
  SubScale: '#30826C',
  Question: '#3659a2',
}
const marginLeft = {
  Principle: 0,
  SubScale: '50px',
  Question: '100px',
}
const SimpleSheet = styled.div(props => ({
  padding: '30px',
  marginTop: '20px',
  backgroundColor: '#fff',
  marginLeft: marginLeft[props.type],
}))
const TitleSection = styled.div(props => ({
  backgroundColor: bgColors[props.type],
  color: '#fff',
  borderRadius: '4px',
  padding: '10px 15px',
  lineHeight: '24px',
  marginBottom: '20px',
  display: 'flex',
}))
const Title = styled.div({})

const renderContent = (key, obj) => {
  if (key === 'imageUrl') {
    return <img style={{ height: 200 }} src={obj.imageUrl} alt="" />
  }
  if (typeof obj[key] === 'boolean') {
    return obj[key] ? 'Yes' : 'No'
  }
  if (typeof obj[key] === 'object') {
    const locales = Object.keys(obj[key])
    return locales.map((locale, index) => (
      <div style={{ marginBottom: 10 }} key={index}>
        <div style={{ fontWeight: '600' }}>{locale}</div>
        <div>{obj[key][locale]}</div>
      </div>
    ))
  }
  return obj[key]
}

const getTypeName = obj => obj.__typename

const ObjectRenderer = ({ obj, index, ...rest }) => {
  return (
    <SimpleSheet type={getTypeName(obj)} key={index}>
      <TitleSection type={getTypeName(obj)}>
        <Title>{getTypeName(obj)}</Title>
        <ActionBar obj={obj} {...rest} />
      </TitleSection>
      {Object.keys(obj).map((key, index) => {
        if (obj[key] && obj[key].constructor === Array) return null
        return (
          <Field key={index}>
            <Label>{decamelize(key)}:</Label>
            <Content>{renderContent(key, obj)}</Content>
          </Field>
        )
      })}
    </SimpleSheet>
  )
}

export default ObjectRenderer
