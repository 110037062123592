import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import textareaStyling from './textareaStyling'
import placeholderStyle from './placeholderStyle'

const StyledInput = styled.textarea`
  ${textareaStyling};
  ${placeholderStyle};
`

const ControlledTextarea = ({ inputType, ...rest }) => (
  <StyledInput type={inputType} {...rest} />
)

ControlledTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.oneOfType([PropTypes.string]).isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string
}

export default ControlledTextarea
