import scaleEnds from '../../../../globals/static-data/scaleEnds'
import { decamelize } from '../../../../globals/utilFunctions'

const scaleEndKeys = Object.keys(scaleEnds)
const scaleEndOjbects = scaleEndKeys.map(item => {
  return {
    value: item,
    text: decamelize(item),
  }
})

export const principleFormFields = ({
  publicTitle,
  internalTitle,
  description,
  imageUrl,
}) => [
  {
    inputType: 'jsonTextInput',
    value: { publicTitle },
  },
  {
    inputType: 'text',
    value: { internalTitle: internalTitle },
  },
  {
    inputType: 'jsonTextarea',
    value: { description: description },
  },
  {
    inputType: 'textarea',
    value: { imageUrl: imageUrl },
  },
]

export const subScaleFormFields = ({
  internalTitle,
  references,
  deviationValue,
  normValue,
}) => [
  {
    inputType: 'text',
    value: { internalTitle },
  },
  {
    inputType: 'textarea',
    value: { references },
  },
  {
    inputType: 'text',
    value: { deviationValue },
  },
  {
    inputType: 'text',
    value: { normValue },
  },
]
export const questionFormFields = ({
  text,
  helpText,
  scaleEnds,
  scaleWidth,
  isReversed,
}) => [
  {
    inputType: 'jsonTextInput',
    value: { text },
  },
  {
    inputType: 'jsonTextInput',
    value: { helpText },
  },
  {
    inputType: 'selectInput',
    value: { scaleEnds },
    options: scaleEndOjbects,
  },
  {
    inputType: 'selectInput',
    value: { scaleWidth },
    options: [
      { value: 'oneToFive', text: 'One to five' },
      { value: 'somethingElse', text: 'Something else' },
    ],
  },
  {
    inputType: 'selectInput',
    value: { isReversed },
    options: [{ value: false, text: 'No' }, { value: true, text: 'Yes' }],
  },
]
