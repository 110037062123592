import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

import HomeLogo from '../globals/components/HabitudLogo'

const Wrapper = styled.div({
  backgroundColor: '#fff',
  height: '100vh',
})
const TopSection = styled.div({
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
})
const Title = styled.div({
  fontSize: '16px',
  lineHeight: '1.5',
  textAlign: 'center',
  marginBottom: 10,
  fontWeight: 600,
})
const ContentWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '100px',
})
const Content = styled.div({
  maxWidth: '320px',
  width: '100%',
})

const SectionSheet = ({ title, children, topRight }) => {
  return (
    <Wrapper>
      <TopSection>
        <NavLink to="/signin">
          <HomeLogo isWithText />
        </NavLink>
      </TopSection>
      <ContentWrapper>
        <Content>
          <Title>{title}</Title>
          {children}
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

export default SectionSheet
