import { Mutation } from 'react-apollo'
import { NamespacesConsumer } from 'react-i18next'
import { gql } from 'apollo-boost'
import { withAlert } from 'react-alert'
import { withRouter } from 'react-router-dom'
import React, { useState } from 'react'
import qs from 'qs'
import styled from 'styled-components/macro'

import AuthSheet from './AuthSheet'
import Button from '../team-admin/common/Button'
import ControlledInput from '../globals/components/form-elements/ControlledInput'

const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`

const DoneAlert = styled.div({
  margin: 20,
})

function ForgotPassword(props) {
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  })
  const [email, setEmail] = useState(query.email)
  const [done, setDone] = useState(false)
  return (
    <NamespacesConsumer>
      {t => (
        <AuthSheet title={t('forgotPassword.mainTitle')}>
          {done ? (
            <DoneAlert>{t('forgotPassword.done')}</DoneAlert>
          ) : (
            <React.Fragment>
              <p>{t('forgotPassword.description')}</p>
              <Mutation mutation={FORGOT_PASSWORD} variables={{ email }}>
                {(forgotPassword, { data, loading, error }) => {
                  return (
                    <form
                      className="form"
                      onSubmit={event => {
                        event.preventDefault()
                        forgotPassword()
                          .then(() => setDone(true))
                          .catch(e => {
                            props.alert.error(
                              e.message.replace('GraphQL error:', ' ').trim()
                            )
                          })
                      }}
                    >
                      <ControlledInput
                        inputType="text"
                        name="email"
                        placeholder={t('formLabels.email')}
                        value={email}
                        onChange={e => {
                          e.preventDefault()
                          setEmail(e.target.value)
                        }}
                      />
                      <Button
                        isFullWidth
                        type="submit"
                        disabled={loading || !email || !/.+@.+/.test(email)}
                      >
                        {t('forgotPassword.button')}
                      </Button>
                    </form>
                  )
                }}
              </Mutation>
            </React.Fragment>
          )}
        </AuthSheet>
      )}
    </NamespacesConsumer>
  )
}

export default withAlert(withRouter(ForgotPassword))
