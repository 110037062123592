import React, { Component } from 'react'

import AntModal from 'antd/lib/modal'
import '../../globals/modal-styling.css'
import DirectActionMutationInner from './DirectActionMutationInner'

class DirectActionMutation extends Component {
  state = { visible: this.props.isModalVisible || false }
  hideModal = () => {
    this.setState({ visible: false })
  }
  showModal = () => {
    this.setState({
      visible: true,
    })
  }
  handleOk = () => {
    this.hideModal()
  }
  handleCancel = () => {
    this.hideModal()
  }
  render() {
    const { modalTrigger, ...mutationInnerProps } = this.props
    return (
      <>
        {modalTrigger({ onClick: this.showModal })}
        <AntModal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DirectActionMutationInner
            {...mutationInnerProps}
            hideModal={this.hideModal}
          />
        </AntModal>
      </>
    )
  }
}

export default DirectActionMutation
