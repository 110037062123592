import React, { Fragment } from 'react'

import Sheet from '../../common/Sheet'
import ObjectRenderer from './bits/ObjectRenderer'
import AdditionGroup from './bits/AdditionGroup'
import {
  principleFormFields,
  subScaleFormFields,
  questionFormFields,
} from './bits/formFields'
import { Field, Label, Content } from './bits/miscStyledComponentElements'
import {
  DELETE_QUESTION,
  UPDATE_QUESTION,
  INSERT_QUESTION_AT,
  DELETE_PRINCIPLE,
  UPDATE_PRINCIPLE,
  INSERT_PRINCIPLE_AT,
  DELETE_SUBSCALE,
  UPDATE_SUBSCALE,
  INSERT_SUBSCALE_AT,
} from '../../graphql/survey-constructor/queriesAndMutations'

const SurveyConstructor = ({
  id: surveyId,
  title,
  version,
  principles,
  conductedSurveys,
}) => {
  return (
    <Fragment>
      <Sheet title={title}>
        <Field>
          <Label>Version</Label>
          <Content>{version}</Content>
        </Field>
      </Sheet>
      {!conductedSurveys.length && (
        <AdditionGroup surveyId={surveyId} principles={principles} />
      )}
      {principles.map(({ subScales, ...principle }) => {
        return (
          <Fragment key={principle.id}>
            <ObjectRenderer
              obj={principle}
              surveyId={surveyId}
              parentId={surveyId}
              objCount={principles.length}
              deleteMutation={DELETE_PRINCIPLE}
              updateMutation={UPDATE_PRINCIPLE}
              insertMutation={INSERT_PRINCIPLE_AT}
              isHavingChildElements={!!subScales.length}
              formFields={principleFormFields(principle)}
            />
            {subScales.map(({ questions, ...subScale }) => {
              return (
                <Fragment key={subScale.id}>
                  <ObjectRenderer
                    obj={subScale}
                    surveyId={surveyId}
                    parentId={principle.id}
                    objCount={subScales.length}
                    deleteMutation={DELETE_SUBSCALE}
                    updateMutation={UPDATE_SUBSCALE}
                    insertMutation={INSERT_SUBSCALE_AT}
                    isHavingChildElements={!!questions.length}
                    formFields={subScaleFormFields(subScale)}
                  />
                  {questions.map((question, index) => (
                    <ObjectRenderer
                      obj={question}
                      key={index}
                      surveyId={surveyId}
                      parentId={principle.id}
                      objCount={questions.length}
                      deleteMutation={DELETE_QUESTION}
                      updateMutation={UPDATE_QUESTION}
                      insertMutation={INSERT_QUESTION_AT}
                      isHavingChildElements={!!conductedSurveys.length}
                      formFields={questionFormFields(question)}
                    />
                  ))}
                </Fragment>
              )
            })}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default SurveyConstructor
