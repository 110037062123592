import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, withRouter } from 'react-router-dom'

import OrganizationSvg from '../svgs/filled/Organizations'
import SurveyConstructorSvg from '../svgs/filled/SurveyConstructor'
import ConductedSurveysSvg from '../svgs/filled/ConductedSurveys'
import SignoutSvg from '../svgs/Signout'
import TransparentButton from '../../globals/components/TransparentButton'
import Signout from '../../auth/Signout'
import HabitudLogo from '../../globals/components/HabitudLogo'

const Wrapper = styled.div({
  flex: '0 0 256px',
  maxWidth: '256px',
  minWidth: '256px',
  width: '256px',
  height: '100vh',
  boxShadow: '2px 0 6px rgba(0,21,41,.35)',
  position: 'fixed',
  zIndex: '10',
  backgroundColor: '#23292f',
  display: 'flex',
  flexDirection: 'column',
})
const TopSection = styled.div({
  color: '#fff',
  height: '64px',
  position: 'relative',
  lineHeight: '64px',
  paddingLeft: '24px',
  overflow: 'hidden',
  borderBottom: '1px solid #2c373d',
  display: 'flex',
  alignItems: 'center',
})
const LogoText = styled.div({
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.5px',
})
const StyledNavLink = styled.div({
  lineHeight: '60px',
  height: '60px',
  paddingRight: '34px',
  paddingLeft: '24px',
  display: 'flex',
  alignItems: 'center',
  color: '#afb4bb',
})
const MainSection = styled.div({
  margin: '20px 0px',
  flex: '1 1 0%',
})
const activeStyleObj = {
  backgroundColor: '#2c373d',
  textDecoration: 'none',
  color: '#fff',
}

const VerticalNavigation = ({ location }) => {
  return (
    <Wrapper>
      <TopSection>
        <HabitudLogo style={{ height: '30px' }} />
        <LogoText style={{ marginLeft: 2 }}>habitud</LogoText>
      </TopSection>
      <MainSection>
        <StyledNavLink
          style={{ color: '#afb4bb' }}
          as={NavLink}
          activeStyle={activeStyleObj}
          to={'/sa/organizations'}
        >
          <OrganizationSvg style={{ color: '#2389ff' }} />
          <span style={{ marginLeft: 10 }}>Organizations</span>
        </StyledNavLink>
        <StyledNavLink
          style={{ color: '#afb4bb' }}
          as={NavLink}
          activeStyle={activeStyleObj}
          to={'/sa/survey-constructor'}
        >
          <SurveyConstructorSvg style={{ color: '#27bfa3' }} />
          <span style={{ marginLeft: 10 }}>Survey Constructor</span>
        </StyledNavLink>
        <StyledNavLink
          style={{ color: '#afb4bb' }}
          as={NavLink}
          activeStyle={activeStyleObj}
          to={'/sa/conducted-surveys'}
        >
          <ConductedSurveysSvg style={{ color: '#fec500' }} />
          <span style={{ marginLeft: 10 }}>Conducted Surveys</span>
        </StyledNavLink>
      </MainSection>
      <Signout
        someRenderProps={props => (
          <TransparentButton
            style={{
              margin: '30px 25px',
              marginRight: 'auto',
              color: '#afb4bb',
              display: 'flex',
              alignItems: 'center',
            }}
            {...props}
          >
            <SignoutSvg />
            <span style={{ marginLeft: 10 }}>Sign out</span>
          </TransparentButton>
        )}
      />
    </Wrapper>
  )
}

export default withRouter(VerticalNavigation)
