// import React, { Component, Fragment } from 'react'
import styled from 'styled-components/macro'

export const MetaWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
})
export const Divider = styled.div({
  margin: '0 10px',
  opacity: '0.4',
})
export const MetaInfo = styled.div({
  display: 'flex',
})
export const MetaKey = styled.div({
  fontWeight: 600,
  marginRight: '5px',
})
