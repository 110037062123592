export default ({ respondents }) => {
  const respondentsCount = respondents.length
  const doneCount = respondents.filter(({ isFinished }) => isFinished).length
  const percentage = Math.floor((doneCount / respondentsCount) * 100) || 0
  return {
    respondentsCount,
    doneCount,
    percentage,
  }
}
