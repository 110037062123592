import React, { useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import Signin from '../auth/Signin'
import ForgotPassword from '../auth/ForgotPassword'
import ResetPassword from '../auth/ResetPassword'

const SsoSignin = withRouter(({ match: { params: { token } } }) => {
  useEffect(() => {
    localStorage.setItem('token', token)
    window.location.href = '/'
  }, [])
  return <div>Redirecting...</div>
})

const SigninRoutes = ({ refetch }) => {
  return (
    <Switch>
      <Route
        path="/forgot-password"
        render={() => <ForgotPassword refetch={refetch} />}
      />
      <Route
        path="/reset-password/:token"
        render={() => <ResetPassword refetch={refetch} />}
      />
      <Route path="/signin" render={() => <Signin refetch={refetch} />} />
      <Route
        path="/sso/:token"
        render={() => <SsoSignin refetch={refetch} />}
      />
      <Redirect to="/signin" />
    </Switch>
  )
}

export default SigninRoutes
