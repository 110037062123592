import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { colors } from '../../globals/variables'

const StyledButton = styled.button`
  background-color: ${colors.defaultBlue};
  padding: 10px 20px;
  border-radius: 21px;
  color: #fff;
  border: none;
  cursor: pointer;
  :active,
  :hover {
    color: #fff;
  }
  :disabled {
    background-color: #ccc;
  }
  ${({ isFullWidth }) =>
    isFullWidth &&
    `
    width: 100%;
    padding: 12px 20px;
  `};
  ${({ isGreen }) =>
    isGreen &&
    `
    background-color: #37bf73;
  `};
`

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)

Button.propTypes = {
  isFullWidth: PropTypes.bool,
}

export default Button
