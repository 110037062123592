import React from 'react'

import Sheet from '../../common/Sheet'
import TeamSection from './bits/TeamSection'
import UserSection from './bits/UserSection'
import TeamAndUserAdditionButtons from './bits/TeamAndUserAdditionButtons'
import { MetaInfo, MetaKey } from './bits/Meta'
import OrganizationSvg from '../../svgs/filled/Organizations'

const Organization = ({
  item: { name, isActivated, teams, id, survey, users, locale },
}) => {
  return (
    <>
      <TeamAndUserAdditionButtons id={id} />
      <Sheet
        icon={<OrganizationSvg />}
        main
        title={`Organization: ${name}`}
        data-testid={'organization'}
      >
        <MetaInfo>
          <MetaKey>Is active:</MetaKey>
          <div>{isActivated.toString()}</div>
        </MetaInfo>
        <MetaInfo>
          <MetaKey>Locale:</MetaKey>
          <div>{locale}</div>
        </MetaInfo>
        <MetaInfo>
          <MetaKey>Survey:</MetaKey>
          <div>
            {survey.title} (v. {survey.id})
          </div>
        </MetaInfo>
      </Sheet>
      {teams.map(team => (
        <TeamSection
          key={team.id}
          organizationId={id}
          team={team}
          orgUsers={users}
        />
      ))}
      <UserSection users={users} organizationId={id} />
    </>
  )
}

export default Organization
