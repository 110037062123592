export default ({ activeSlice, resultPrinciples, filteredContentfulData }) => {
  if (activeSlice === undefined) {
    const allPrinciples = resultPrinciples.map(
      (item, index) => filteredContentfulData[index]
    )
    const allSummaries = allPrinciples.map(item => item.summary)
    const ingress = allSummaries.shift()
    const obj = {
      ingress,
      feedback: allSummaries.join(' '),
    }
    return obj
  } else {
    const item = filteredContentfulData[activeSlice]
    const obj = {
      ingress: item.ingress,
      feedback: item.feedback,
    }
    return obj
  }
}
