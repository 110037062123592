import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'

const TopRightDate = styled.div({
  fontSize: '13px',
  marginLeft: 'auto',
  minWidth: '130px',
  color: colors.generalFontGrey,
})

export default TopRightDate
