import { gql } from 'apollo-boost'

export const fragments = {
  userWithTeamAccess: gql`
    fragment UserWithTeamAccess on User {
      id
      firstName
      lastName
      email
      role
      isActive
    }
  `,
  user: gql`
    fragment CompleteUser on User {
      id
      firstName
      lastName
      email
      defaultTeam {
        id
        name
      }
    }
  `,
  conductedSurvey: gql`
    fragment CompleteConductedSurvey on ConductedSurvey {
      id
      isActive
      identifier
      result
      title
      createdAt
      endedAt
      message
      survey {
        id
      }
    }
  `,
}
export const secondaryFragments = {
  team: gql`
    fragment CompleteTeam on Team {
      id
      name
      isActivated
      inactiveUsers {
        ...UserWithTeamAccess
      }
      activeUsers {
        ...UserWithTeamAccess
      }
      activeMembers {
        ...CompleteUser
      }
    }
    ${fragments.user}
    ${fragments.userWithTeamAccess}
  `,
  respondent: gql`
    fragment CompleteRespondent on Respondent {
      id
      isFinished
      identifier
      user {
        ...CompleteUser
      }
    }
    ${fragments.user}
  `,
}
