import React from 'react'
import styled from 'styled-components/macro'
import { NamespacesConsumer } from 'react-i18next'

import { CREATE_TEAM_ACCESS } from '../../../graphql/mutations'

import { colors as globalColors } from '../../../globals/variables'
import Sheet from '../../common/Sheet'
import AddSign from '../../common/svgs/AddSign'
import UserRow from './bits/UserRow'
import ModalFormMutation from '../../common/ModalFormMutation'
import TransparentButton from '../../../globals/components/TransparentButton'

const teamAccessFields = ({
  userId = '',
  usersAsSelectOptions,
  placeholder,
} = {}) => {
  return [
    {
      inputType: 'selectInput',
      value: { userId },
      options: usersAsSelectOptions,
      placeholder,
    },
  ]
}

const renderTopRight = ({ teamId, usersAsSelectOptions, t }) => {
  const placeholder = t('members.actions.addUser.selectPlaceholder')
  return (
    <ModalFormMutation
      mutation={CREATE_TEAM_ACCESS}
      additionalVariables={{ teamId }}
      fields={teamAccessFields({ usersAsSelectOptions, placeholder })}
      modalTitle={t('members.actions.addUser.modalTitle')}
      contentText={t('members.actions.addUser.modalDescr')}
      buttonText={t('members.actions.addUser.modalButton')}
      successMsg={t('members.actions.addUser.successMessage')}
      isLabelHidden={true}
      modalTrigger={props => {
        return (
          <TransparentButton {...props}>
            <StyledAddSign />
          </TransparentButton>
        )
      }}
    />
  )
}

const Items = styled.div({
  padding: '0',
})
const StyledAddSign = styled(AddSign)({
  color: globalColors.defaultBlue,
  height: '30px',
  width: '30px',
})

const Members = ({
  teamId,
  usersAsSelectOptions,
  activeUsers,
  inactiveUsers,
}) => (
  <NamespacesConsumer>
    {t => (
      <>
        <Sheet
          subPage
          narrow
          title={t('members.activeList')}
          topRight={renderTopRight({ teamId, usersAsSelectOptions, t })}
        >
          <Items>
            {activeUsers
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .map(user => (
                <UserRow
                  key={user.id}
                  {...user}
                  teamId={teamId}
                  isActive={true}
                  t={t}
                />
              ))}
          </Items>
        </Sheet>
        {!!inactiveUsers.length && (
          <Sheet narrow subPage title={t('members.inactiveList')}>
            <Items>
              {inactiveUsers
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(user => (
                  <UserRow
                    key={user.id}
                    {...user}
                    teamId={teamId}
                    isActive={false}
                    t={t}
                  />
                ))}
            </Items>
          </Sheet>
        )}
      </>
    )}
  </NamespacesConsumer>
)

export default Members
