import styled from 'styled-components/macro'

const PageWrapper = styled.div`
  display: flex;
  margin-top: 75px;
  flex-direction: column;
  align-items: center;
`

export default PageWrapper
