import { Mutation } from 'react-apollo'
import { NamespacesConsumer } from 'react-i18next'
import { gql } from 'apollo-boost'
import { withAlert } from 'react-alert'
import { withRouter } from 'react-router-dom'
import React, { useState } from 'react'

import AuthSheet from './AuthSheet'
import Button from '../team-admin/common/Button'
import ControlledInput from '../globals/components/form-elements/ControlledInput'

const RESET_PASSWORD = gql`
  mutation($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      token
    }
  }
`

function ResetPassword(props) {
  const {
    match: {
      params: { token },
    },
  } = props
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  return (
    <NamespacesConsumer>
      {t => (
        <AuthSheet title={t('resetPassword.mainTitle')}>
          <p>{t('resetPassword.description')}</p>
          <Mutation mutation={RESET_PASSWORD} variables={{ password, token }}>
            {(resetPassword, { data, loading, error }) => {
              return (
                <form
                  className="form"
                  onSubmit={event => {
                    event.preventDefault()
                    resetPassword()
                      .then(async ({ data }) => {
                        const { resetPassword } = data
                        localStorage.setItem('token', resetPassword.token)
                        props.alert.show(t('resetPassword.success'))
                        await props.refetch()
                      })
                      .catch(e => {
                        props.alert.error(
                          e.message.replace('GraphQL error:', ' ').trim()
                        )
                      })
                  }}
                >
                  <ControlledInput
                    inputType="password"
                    name="password"
                    placeholder={t('formLabels.password')}
                    autoComplete="off"
                    value={password}
                    onChange={e => {
                      e.preventDefault()
                      setPassword(e.target.value)
                    }}
                  />
                  <ControlledInput
                    inputType="password"
                    name="password2"
                    placeholder={t('resetPassword.retypePassword')}
                    autoComplete="off"
                    value={password2}
                    onChange={e => {
                      e.preventDefault()
                      setPassword2(e.target.value)
                    }}
                  />
                  <Button
                    isFullWidth
                    type="submit"
                    disabled={
                      loading ||
                      !password ||
                      password !== password2 ||
                      password.length < 6
                    }
                  >
                    {t('resetPassword.button')}
                  </Button>
                </form>
              )
            }}
          </Mutation>
        </AuthSheet>
      )}
    </NamespacesConsumer>
  )
}

export default withAlert(withRouter(ResetPassword))
