import { css } from 'styled-components/macro'

export default css({
  minHeight: '100px',
  lineHeight: 'inherit',
  padding: '5px 10px',
  marginTop: '10px',
  boxSizing: 'border-box',
  width: '100%',
  border: '1px solid #ddd',
  outline: 'none',
  marginBottom: '15px'
})
