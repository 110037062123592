import React from 'react'
import { NavLink } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import Button from '../../../common/Button'
import SectionSheet from '../../../common/Sheet'
import EmptySectionPlaceholder from './EmptySectionPlaceholder'
import SurveySectionRow from './SurveySectionRow'

const SurveySection = ({ conductedSurveys, teamId }) => (
  <NamespacesConsumer>
    {t => (
      <SectionSheet
        title={t('home.surveySectionTitle')}
        topRight={
          <Button as={NavLink} to="/conducted-survey/new">
            {t('home.startButton')}
          </Button>
        }
      >
        {conductedSurveys.length ? (
          conductedSurveys
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map(conductedSurvey => (
              <SurveySectionRow
                key={conductedSurvey.id}
                {...{ ...conductedSurvey, teamId }}
              />
            ))
        ) : (
          <EmptySectionPlaceholder>
            {t('home.conductedSurveySectionEmptyPlaceholder')}
          </EmptySectionPlaceholder>
        )}
      </SectionSheet>
    )}
  </NamespacesConsumer>
)

export default SurveySection
