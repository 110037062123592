import React from 'react'
import { BarLoader } from 'react-spinners'
import styled from 'styled-components/macro'

import { colors } from '../variables'

const Wrapper = styled.div({
  margin: '100px auto',
  width: '100px',
})

export const Preloader = () => (
  <Wrapper>
    <BarLoader color={colors.defaultBlue} />
  </Wrapper>
)

export const Error = ({ error }) => (
  <p>{error && error.message.replace('GraphQL error:', ' ').trim()}</p>
)
