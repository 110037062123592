import React from 'react'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'

import { CONDUCTED_SURVEY } from '../../../graphql/queries'

import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'
import doneRate from './bits/doneRate'
import ConductedSurvey from './ConductedSurvey'

const ConductedSurveyContainer = ({
  history,
  match: {
    params: { id },
  },
}) => (
  <Query query={CONDUCTED_SURVEY} variables={{ id }}>
    {({ data, error, loading }) => {
      if (error) return <Error />
      if (loading) return <Preloader />
      const {
        title,
        createdAt,
        respondents,
        message,
        identifier: conductedSurveyIdentifier,
      } = data.conductedSurvey
      const { respondentsCount, percentage, doneCount } = doneRate({
        respondents,
      })
      return (
        <ConductedSurvey
          {...{
            history,
            id,
            title,
            createdAt,
            doneCount,
            respondentsCount,
            percentage,
            respondents,
            conductedSurveyIdentifier,
            message,
          }}
        />
      )
    }}
  </Query>
)

export default withRouter(ConductedSurveyContainer)
