export const colors = {
  defaultText: '#333',
  defaultBlue: '#67bdff',
  generalFontGrey: '#657785',
  generalGreyBorder: '#eee',
  screamingGreen: '#49c400',
  linkBlue: '#40a9ff',
}
export const boxShadows = {
  defaultSheet: '0 1px 5px 0 rgba(45, 62, 80, 0.12)',
}
export const borderRadiuses = {
  defaultSheet: '5px',
}

export const mobileWidth = '400px'
