import { gql } from 'apollo-boost'

import { fragments, secondaryFragments } from '../../graphql/fragments'
import { localFragments } from './localFragments'

export const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const DELETE_ORGANIZATION = gql`
  mutation($id: ID!) {
    deleteOrganization(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const UPDATE_ORGANIZATION = gql`
  mutation($id: ID!, $name: String, $isActivated: Boolean) {
    updateOrganization(id: $id, name: $name, isActivated: $isActivated) {
      ...CompleteOrganization
      survey {
        title
        version
      }
      teams {
        ...CompleteTeam
        users {
          ...UserWithTeamAccess
        }
      }
    }
  }
  ${localFragments.organization}
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
`
export const CREATE_ORGANIZATION = gql`
  mutation(
    $name: String!
    $locale: String!
    $isActivated: Boolean!
    $isTestPurpose: Boolean!
    $surveyId: ID!
  ) {
    createOrganization(
      name: $name
      locale: $locale
      isActivated: $isActivated
      isTestPurpose: $isTestPurpose
      surveyId: $surveyId
    ) {
      ...CompleteOrganization
      survey {
        title
        version
      }
      teams {
        ...CompleteTeam
        users {
          ...UserWithTeamAccess
        }
      }
    }
  }
  ${localFragments.organization}
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
`
export const CREATE_USER = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $organizationId: ID!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organizationId: $organizationId
    ) {
      ...CompleteUser
    }
  }
  ${fragments.user}
`
export const CREATE_TEAM = gql`
  mutation($name: String!, $isActivated: Boolean!, $organizationId: ID!) {
    createTeam(
      name: $name
      isActivated: $isActivated
      organizationId: $organizationId
    ) {
      ...CompleteTeam
      organization {
        ...CompleteOrganization
      }
    }
  }
  ${localFragments.organization}
  ${secondaryFragments.team}
`
export const UPDATE_TEAM = gql`
  mutation($id: ID!, $name: String, $isActivated: Boolean) {
    updateTeam(id: $id, name: $name, isActivated: $isActivated) {
      ...CompleteTeam
    }
  }
  ${secondaryFragments.team}
`
export const DELETE_TEAM = gql`
  mutation($id: ID!) {
    deleteTeam(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
