import React, { Component } from 'react'
import styled from 'styled-components/macro'
import AntModal from 'antd/lib/modal'
import '../../globals/modal-styling.css'

import FormConstructor from './FormConstructor'

const Wrapper = styled.div({})
const Title = styled.div({
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: '600',
  borderBottom: '1px solid #eee',
  padding: '10px 0 30px',
})
const Content = styled.div({
  margin: '20px',
})

class ModalFormMutation extends Component {
  state = { visible: this.props.isModalVisible || false }
  hideModal = () => {
    this.setState({ visible: false })
  }
  showModal = () => {
    this.setState({
      visible: true,
    })
  }
  handleOk = () => {
    this.hideModal()
  }
  handleCancel = () => {
    this.hideModal()
  }
  render() {
    const {
      modalTrigger,
      modalTitle,
      contentText,
      ...formStructorProps
    } = this.props
    return (
      <>
        {modalTrigger({ onClick: this.showModal })}
        <AntModal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Wrapper>
            <Title>{modalTitle}</Title>
            <Content>{contentText}</Content>
            <FormConstructor
              {...formStructorProps}
              postMutationFunction={this.hideModal}
            />
          </Wrapper>
        </AntModal>
      </>
    )
  }
}

export default ModalFormMutation
