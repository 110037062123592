import React, { Component } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import PolarChart from '../../../common/Polarchart'
import SectionWrapper from './SectionWrapper'
import SectionTitle from './SectionTitle'
import principleColors from './principleColors'
import RightSideTopSection from './RightSideTopSection'
import { colors } from '../../../../globals/variables'
import TopRightDate from './TopRightDate'

const LeftSide = styled.div({
  width: '50%',
  padding: '20px 30px',
})
const ResultTitle = styled.div({
  color: colors.generalFontGrey,
})
const ChartWrapper = styled.div({
  width: 250,
  margin: '0 auto',
})

const leftSide = ({
  maxSurveyPoints,
  activeSlice,
  handleClick,
  resultTotalSum,
  resultPrinciples,
  t,
}) => {
  const slices = resultPrinciples
    .sort((a, b) => a.position - b.position)
    .map((item, index) => {
      return {
        label: index + 1,
        value: item.totalAverage,
        color: principleColors[index],
      }
    })
  return (
    <LeftSide>
      <ResultTitle>{t('report.resultLeftSide')}</ResultTitle>
      <ChartWrapper>
        <PolarChart
          score={resultTotalSum}
          {...{ maxScore: maxSurveyPoints, activeSlice }}
          onSliceSelected={(slice, index) => handleClick(index)}
          slices={slices}
        />
      </ChartWrapper>
    </LeftSide>
  )
}

class TopSection extends Component {
  state = {
    activeSlice: undefined,
    showLongFeedback: false,
  }
  handleClick = index => {
    this.setState({ activeSlice: index })
  }
  handleFeedbackClick = () => {
    this.setState({ showLongFeedback: true })
  }
  render() {
    const { activeSlice, showLongFeedback } = this.state
    const {
      normPrinciples,
      team,
      conductedSurveyTitle,
      principles,
      endedAt,
      resultTotalSum,
      resultPrinciples,
      t,
      ...rest
    } = this.props
    const maxSurveyPoints = normPrinciples.length * 100
    return (
      <>
        <SectionTitle>
          <div>{`${team.name} - ${t(
            'report.mainTitle'
          )} ${conductedSurveyTitle}`}</div>
          <TopRightDate style={{ marginLeft: 'auto' }}>
            {moment(endedAt).from(new Date())}
          </TopRightDate>
        </SectionTitle>
        <SectionWrapper isWithBorder>
          {leftSide({
            maxSurveyPoints,
            activeSlice,
            handleClick: this.handleClick,
            resultTotalSum,
            resultPrinciples,
            t,
          })}
          <RightSideTopSection
            {...{
              index: activeSlice,
              principles,
              normPrinciples,
              maxSurveyPoints,
              resultTotalSum,
              showLongFeedback,
              resultPrinciples,
              t,
              ...rest,
            }}
            handleFeedbackClick={this.handleFeedbackClick}
          />
        </SectionWrapper>
      </>
    )
  }
}
export default TopSection
