import styled from 'styled-components/macro'

export const Field = styled.div({
  display: 'flex',
  marginBottom: '10px',
})
export const Label = styled.div({
  fontWeight: 600,
  minWidth: '110px',
})
export const Content = styled.div({
  marginLeft: '10px',
})

export const ActionBarIcon = styled.svg(({ unclickable }) => ({
  height: '30px',
  width: '30px',
  marginLeft: '10px',
  cursor: unclickable ? 'arrow' : 'pointer',
  opacity: unclickable ? '0.4' : '1',
}))

export const ActionsWrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
})
