import styled from 'styled-components/macro'

import { colors } from '../../globals/variables'
import TransparentButton from '../../globals/components/TransparentButton'

const Button = styled(TransparentButton)(({ small, white }) => ({
  backgroundColor: colors.defaultBlue,
  color: '#fff',
  borderRadius: '2px',
  padding: '15px 45px',
  ...(small && {
    fontSize: '12px',
    padding: '12px 20px',
  }),
  ...(white && {
    backgroundColor: '#fff',
    color: '#888',
    border: '1px solid #ddd',
  }),
}))

export default Button
