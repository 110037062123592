import React from 'react'

import ControlledInput from './ControlledInput'

const ControlledJsonInput = ({
  objName,
  values,
  handleJsonChange,
  ...rest
}) => {
  return Object.keys(values).map(key => (
    <div key={key}>
      <div>{key}</div>
      <ControlledInput
        inputType="text"
        name={`${objName}-${key}`}
        value={values[key]}
        onChange={event => handleJsonChange(objName, key, event)}
        {...rest}
      />
    </div>
  ))
}

export default ControlledJsonInput
