import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'

const StyledSection = styled.div({
  marginBottom: '30px',
})
const SectionTitle = styled.div({
  fontSize: '14px',
  fontWeight: '600',
  marginBottom: '8px',
})
const SectionContent = styled.div({
  color: colors.generalFontGrey,
  marginLeft: '10px',
})

const Section = ({ title, children, ...rest }) => (
  <StyledSection {...rest}>
    <SectionTitle>{title}</SectionTitle>
    <SectionContent>{children}</SectionContent>
  </StyledSection>
)

export default Section
