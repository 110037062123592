import React from 'react'
import styled from 'styled-components/macro'

import Section from './Section'
import inputStyling, {
  placeholderStyle,
} from '../../../../globals/components/form-elements/inputStyling'
import { colors } from '../../../../globals/variables'

const StyledTextArea = styled.textarea`
  ${inputStyling};
  ${placeholderStyle};
  min-height: 100px;
  line-height: inherit;
  padding: 5px 10px;
  margin-top: 10px;
  color: ${colors.defaultText};
`

const showTextarea = variable => typeof variable === 'function'

const MessageSection = ({ handleChange, message, title, content }) => {
  if (handleChange === undefined && message === undefined) return null
  return (
    <Section title={`3. ${title}`}>
      {showTextarea(handleChange) ? (
        <div>
          <div>{content}</div>
          <StyledTextArea onChange={handleChange} rows="4" />
        </div>
      ) : (
        <div>{message}</div>
      )}
    </Section>
  )
}

export default MessageSection
