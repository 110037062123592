import React from 'react'

import { Query } from 'react-apollo'
import { TEAM_WITH_ORGANIZATION } from '../../../graphql/queries'

import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'
import renderOrgUsersAsSelectOptions from '../../../globals/renderOrgUsersAsSelectOptions'
import Members from './Members'

const MembersContainer = ({ teamId }) => (
  <Query query={TEAM_WITH_ORGANIZATION}>
    {({ loading, error, data }) => {
      if (loading) return <Preloader />
      if (error) return <Error />
      const {
        inactiveUsers,
        activeUsers,
        users: teamUsers,
        orgUsers,
      } = data.team
      const usersAsSelectOptions = renderOrgUsersAsSelectOptions({
        orgUsers,
        teamUsers,
      })
      return (
        <Members
          {...{
            teamId,
            usersAsSelectOptions,
            activeUsers,
            inactiveUsers,
          }}
        />
      )
    }}
  </Query>
)

export default MembersContainer
