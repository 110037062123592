import { gql } from 'apollo-boost'

export const localFragments = {
  organization: gql`
    fragment CompleteOrganization on Organization {
      id
      name
      locale
      isActivated
      isTestPurpose
      users {
        id
      }
    }
  `,
  userWithTeamAccess: gql`
    fragment UserWithTeamAccess on User {
      id
      firstName
      lastName
      email
      role
      isActive
    }
  `,
}
