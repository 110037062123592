import React from 'react'
import styled from 'styled-components/macro'

import logo from '../images/habitud-logo-symbol.png'
import logoWithText from '../images/logo-with-text.png'

const HabitudLogo = styled.img(({ height }) => ({
  height: height ? height : '50px',
}))

export default ({ isWithText, ...rest }) => (
  <HabitudLogo {...rest} src={isWithText ? logoWithText : logo} />
)
