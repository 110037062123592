import React from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { SURVEY } from '../../graphql/survey-constructor/queriesAndMutations'

import SurveyConstructor from './SurveyConstructor'
import Preloader from '../../../globals/components/Preloader'
import Error from '../../../globals/components/Error'

const SurveyConstructorContainer = props => (
  <Query query={SURVEY} variables={{ id: props.match.params.id }}>
    {({ loading, error, data }) => {
      if (loading) return <Preloader />
      if (error) return <Error />
      return <SurveyConstructor {...data.survey} />
    }}
  </Query>
)

export default withRouter(SurveyConstructorContainer)
