import styled from 'styled-components/macro'

import TransparentButton from '../../globals/components/TransparentButton'
import { colors } from '../../globals/variables'

const ActionButton = styled(TransparentButton)({
  color: colors.defaultBlue,
})

export default ActionButton
