import React from 'react'
import { Query } from 'react-apollo'

import { CURRENT_USER } from '../graphql/queries'

const withSession = Component => props => (
  <Query query={CURRENT_USER}>
    {({ data, error, loading, refetch }) => {
      if (loading) return null
      if (error) return null
      return (
        <Component
          {...props}
          refetch={refetch}
          currentUser={data.currentUser}
        />
      )
    }}
  </Query>
)

export default withSession
