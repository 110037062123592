import React from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import { END_RESPONDENT } from '../../graphql/mutations'

import Button from '../common/Button'

const FinalSubmission = ({ history, match }) => {
  const { conductedSurveyIdentifier, respondentIdentifier } = match.params
  return (
    <NamespacesConsumer>
      {t => (
        <Mutation
          mutation={END_RESPONDENT}
          variables={{
            conductedSurveyIdentifier,
            respondentIdentifier,
            isFinished: true,
          }}
        >
          {mutationFunction => (
            <div
              data-testid={'finalSubmission'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 100,
              }}
            >
              <div>{t('survey.finalSubmission.message')}</div>
              <Button
                onClick={() => {
                  mutationFunction().then(({ data }) => {
                    console.log('TCL: handleClick -> data', data)
                    history.push('fin')
                  })
                }}
                style={{ marginTop: 30 }}
              >
                {t('survey.finalSubmission.button')}
              </Button>
            </div>
          )}
        </Mutation>
      )}
    </NamespacesConsumer>
  )
}

export default withRouter(FinalSubmission)
