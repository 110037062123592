import styled from 'styled-components/macro'

import {
  colors,
  boxShadows,
  borderRadiuses,
} from '../../../../globals/variables'

const SectionWrapper = styled.div(({ isWithBorder }) => ({
  display: 'flex',
  marginTop: '40px',
  marginBottom: '40px',
  ...(isWithBorder && {
    border: '1px solid',
    borderRadius: borderRadiuses.defaultSheet,
    borderColor: colors.generalGreyBorder,
    boxShadow: boxShadows.defaultSheet,
  }),
}))

export default SectionWrapper
