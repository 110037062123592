import React, { Component } from 'react'

import Modal from '../Modal'
import FieldGroup from './FieldGroup'
import SaveButtonWithFeedback from './SaveButtonWithFeedback'
import Error from '../../../globals/components/Error'
import TransparentButton from '../../../globals/components/TransparentButton'
import EllipsisIcon from '../../svgs/Ellipsis'
import { decamelize } from '../../../globals/utilFunctions'
import renderField from '../../../globals/renderField'

class ModalWithform extends Component {
  render() {
    const {
      mutationFunction,
      error,
      data,
      modalTitle,
      fields,
      handleSubmit,
      handleChange,
      handleJsonChange,
      modalTrigger,
      state,
      ...props
    } = this.props
    return (
      <Modal
        {...props}
        title={modalTitle}
        formContent={
          <form onSubmit={event => handleSubmit(event, mutationFunction)}>
            {fields.map(item => (
              <FieldGroup
                key={Object.keys(item.value)[0]}
                label={decamelize(Object.keys(item.value)[0])}
                input={renderField(item, handleChange, handleJsonChange, state)}
              />
            ))}
            {error && <Error error={error} />}
            <SaveButtonWithFeedback data={data} />
          </form>
        }
        render={props => {
          if (modalTrigger) {
            return modalTrigger({ ...props })
          }
          return (
            <TransparentButton {...props}>
              <EllipsisIcon style={{ opacity: '0.6' }} />
            </TransparentButton>
          )
        }}
      />
    )
  }
}

export default ModalWithform
