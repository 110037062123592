import React from 'react'
import { Mutation } from 'react-apollo'

import { isNumeric } from '../../../../globals/utilFunctions'
import ArrowUpIcon from '../../../svgs/ArrowUp'
import ArrowDownIcon from '../../../svgs/ArrowDown'
import { ActionBarIcon } from './miscStyledComponentElements'
import { SURVEY } from '../../../graphql/survey-constructor/queriesAndMutations'

const RenderMutation = ({
  insertMutation,
  surveyId,
  parentId,
  id,
  newPosition,
  direction,
  render,
}) => (
  <Mutation
    mutation={insertMutation}
    variables={{
      parentId,
      id,
      newPosition,
      direction,
    }}
    refetchQueries={[
      {
        query: SURVEY,
        variables: { id: surveyId },
      },
    ]}
  >
    {(mutationFunction, { data }) => render({ onClick: mutationFunction })}
  </Mutation>
)

export default ({ position, objCount, ...rest }) => {
  if (!isNumeric(position) || objCount === 1) {
    return null
  }
  const isFirstItem = position => position === 0
  const isLastItem = (position, objCount) => position === objCount - 1

  return (
    <>
      <RenderMutation
        {...rest}
        direction="up"
        newPosition={position - 1}
        render={props => (
          <ActionBarIcon
            unclickable={isFirstItem(position)}
            {...(isFirstItem(position) ? {} : props)}
            as={ArrowUpIcon}
          />
        )}
      />
      <RenderMutation
        {...rest}
        direction="down"
        newPosition={position + 1}
        render={props => (
          <ActionBarIcon
            unclickable={isLastItem(position, objCount)}
            {...(isLastItem(position, objCount) ? {} : props)}
            as={ArrowDownIcon}
          />
        )}
      />
    </>
  )
}
