import React from 'react'
import styled from 'styled-components/macro'
import { NamespacesConsumer } from 'react-i18next'

import { colors } from '../../globals/variables'

function getSubjectColor(subject) {
  if (subject === 'Lyckligare på jobbet') {
    return colors.screamingGreen
  }
  return colors.defaultBlue
}

const Wrapper = styled.a({
  display: 'block',
  textAlign: 'left',
  border: '1px solid rgba(0,0,0,0.13)',
  boxShadow: '0 3px 15px rgba(0,0,0,0.11)',
  borderRadius: '5px',
  margin: '10px 0',
  color: 'inherit',
})
const Top = styled.div({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
  overflow: 'hidden',
})
const TopInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  minHeight: 150,
  textAlign: 'center',
  padding: 10,
  backgroundColor: 'rgba(0,0,0,0.5)',
  backgroundPosition: 'center center',
})
const HCSubject = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  color: '#fff',
  padding: 5,
  width: 200,
  textAlign: 'center',
  transform: 'rotate(-34deg) translate(-30%,-82%)',
  fontSize: 10,
})
const HCTitle = styled.div({
  fontSize: 24,
  fontWeight: 600,
})
const HCDescription = styled.div({
  maxHeight: '125px',
  overflow: 'hidden',
  position: 'relative',
  padding: 10,
  color: colors.generalFontGrey,
  fontSize: 14,
  lineHeight: '20px',
})
const Fader = styled.div({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundImage: 'linear-gradient(rgba(255,255,255,0) 50%, #fff 100%)',
})

const Bottom = styled.div({
  padding: '10px 10px 4px 10px',
  textAlign: 'center',
  fontSize: '10px',
  borderTop: '1px solid rgba(0,0,0,0.05)',
  color: '#B4B4B4',
})

const CardEdge = styled.div({
  height: 6,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  background: '#fff',
  border: '1px solid rgba(0,0,0,0.13)',
  borderTop: 'none',
  margin: '0 -1px',
})

const HabitudCourse = ({
  hCTitle,
  hCImage,
  hCDescription,
  hCUrl,
  hCSubject,
  hCChallengesCount,
}) => {
  return (
    <NamespacesConsumer>
      {t => (
        <Wrapper href={hCUrl} target="_blank" rel="noopener noreferrer">
          <Top style={{ backgroundImage: `url(${hCImage})` }}>
            <HCSubject style={{ backgroundColor: getSubjectColor(hCSubject) }}>
              {hCSubject}
            </HCSubject>
            <TopInner>
              <HCTitle>{hCTitle}</HCTitle>
            </TopInner>
          </Top>
          <HCDescription>
            <div>{hCDescription}</div>
            <Fader />
          </HCDescription>
          <Bottom>
            {t('report.bottomSection.challengesCount', {
              count: hCChallengesCount,
            })}
          </Bottom>
          <CardEdge />
          <CardEdge />
          <CardEdge />
          <CardEdge style={{ marginBottom: -1 }} />
        </Wrapper>
      )}
    </NamespacesConsumer>
  )
}

export default HabitudCourse
