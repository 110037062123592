import React from 'react'
import { Query } from 'react-apollo'

import { TEAM } from '../../../graphql/queries'

import NewConductedSurvey from './NewConductedSurvey'
import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'

const NewConductedSurveyContainer = ({ team: { id: teamId } }) => (
  <Query query={TEAM}>
    {({ data, error, loading }) => {
      if (error) return <Error />
      if (loading) return <Preloader />
      const respondents = data.team.activeMembers
      return (
        <NewConductedSurvey
          {...{
            teamId,
            respondents,
          }}
        />
      )
    }}
  </Query>
)

export default NewConductedSurveyContainer
