import React from 'react'
import styled from 'styled-components/macro'

const StyledSvgIcon = styled.svg({
  fill: 'currentColor',
  display: 'inline-block',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  userSelect: 'none',
  flexShrink: '0',
  height: '20px',
  width: '20px',
})

const SvgShell = ({ children, viewBox, ...props }) => (
  <StyledSvgIcon
    height="100%"
    width="100%"
    viewBox={viewBox ? viewBox : '0 0 20 20'}
    {...props}
  >
    {children}
  </StyledSvgIcon>
)

export default SvgShell
