import React, { Component } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import BottomSectionRow from './BottomSectionRow'
import SectionTitle from './SectionTitle'
import getDifference from './getDifference'
import { colors } from '../../../../globals/variables'
import ControlledSelectInput from '../../../../globals/components/form-elements/ControlledSelectInput'
import getHabitudCoursesMetaData from '../../../common/getHabitudCoursesMetaData'

const Introduction = styled.div({
  margin: '50px 0',
})
const Table = styled.div({
  textAlign: 'center',
})
const ListHeader = styled.div({
  color: colors.generalFontGrey,
  display: 'flex',
  marginBottom: '10px',
  lineHeight: '15px',
  alignItems: 'center',
})
const ListBottom = styled.div({
  display: 'flex',
  paddingLeft: '15px',
  fontWeight: 600,
  backgroundColor: '#f6f6f6',
  height: '40px',
  borderRadius: '5px',
  alignItems: 'center',
})
const StyledControlledSelectInput = styled(ControlledSelectInput)({
  marginLeft: 'auto',
  maxWidth: '172px',
  width: '100%',
})

const differenceWithPlusMinus = (difference, positive) => {
  if (difference === 0) {
    return difference
  }
  if (positive) {
    return `+${difference}`
  }
  return `-${difference}`
}

class BottomSection extends Component {
  state = {
    indexOfSelectedComparableConductedSurvey: 0,
    habitudCourses: [],
  }
  componentDidMount() {
    this.getHabitudCourses()
  }
  getHabitudCourses = async () => {
    const habitudCourses = await getHabitudCoursesMetaData()
    this.setState({ habitudCourses })
  }
  handleComaparisonChange = event => {
    this.setState({
      indexOfSelectedComparableConductedSurvey: event.target.value,
    })
  }
  render() {
    const {
      normPrinciples,
      normTotalValue,
      locale,
      principles,
      resultPrinciples,
      resultTotalSum,
      comparableConductedSurveys,
      t,
    } = this.props
    const [difference, positive] = getDifference({
      points: resultTotalSum,
      normValue: normTotalValue,
    })
    const {
      indexOfSelectedComparableConductedSurvey: indexOfSelected,
      habitudCourses,
    } = this.state
    let comparableResult
    let selectOptions = [
      {
        value: 0,
        text: t('report.bottomSection.emptyComparableList'),
      },
    ]
    if (!!comparableConductedSurveys.length) {
      comparableResult = comparableConductedSurveys[indexOfSelected].result
      selectOptions = comparableConductedSurveys.map((item, index) => {
        return {
          value: index,
          text: `${t('report.bottomSection.comparableListTitle', {
            number: item.title,
          })} - ${moment(item.createdAt).format('YYYY-MM-DD')}`,
        }
      })
    } else {
    }
    return (
      <>
        <SectionTitle>{t('report.bottomSection.title')}</SectionTitle>
        <>
          <Introduction>{t('report.bottomSection.description')}</Introduction>
          <Table>
            <ListHeader>
              <div style={{ width: 345, textAlign: 'left', paddingLeft: 25 }} />
              <div style={{ width: 90 }}>
                {t('report.bottomSection.points')}
              </div>
              <div style={{ width: 115 }}>
                {t('report.bottomSection.index')}
              </div>
              <StyledControlledSelectInput
                name={'comparableSurveys'}
                selectedOption={indexOfSelected}
                options={selectOptions}
                controlFunc={this.handleComaparisonChange}
                style={{ marginBottom: 0, color: colors.generalFontGrey }}
              />
            </ListHeader>
            {principles.map((principle, index) => (
              <BottomSectionRow
                key={principle.id}
                habitudCourses={habitudCourses[index]}
                t={t}
                comparableResult={
                  comparableResult &&
                  comparableResult.principles.find(item => {
                    return item.id.toString() === principle.id
                  })
                }
                {...{
                  principle,
                  index,
                  locale,
                  resultPrinciples,
                  normPrinciples,
                }}
              />
            ))}
            <ListBottom>
              <div style={{ width: 360, textAlign: 'left' }}>
                {t('report.bottomSection.total')}
              </div>
              <div style={{ width: 90 }}>
                {resultTotalSum} / {principles.length * 100}
              </div>
              <div style={{ width: 115 }}>
                {differenceWithPlusMinus(difference, positive)}
              </div>
              <div
                style={{
                  padding: '10px',
                  marginLeft: 'auto',
                  maxWidth: '150px',
                  width: '100%',
                }}
              >
                {comparableResult && comparableResult.totalSum}
              </div>
            </ListBottom>
          </Table>
        </>
      </>
    )
  }
}
export default BottomSection
