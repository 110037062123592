import React, { Component } from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { createClient } from 'contentful'
import styled from 'styled-components/macro'

import Sheet from '../../../common/Sheet'
import getHabitudCoursesMetaData from '../../../common/getHabitudCoursesMetaData'
import HabitudCourse from '../../../common/HabitudCourse'

const contentfulClient = createClient({
  space: '29wnk0q9jemm',
  accessToken:
    'fe80d24b6687551f3d34ee23b6d9cedbddf38589369225dec43e9e9c6f0b7412',
})

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`

class CoursesSection extends Component {
  state = {
    habitudCourses: [],
  }
  componentDidMount() {
    this.getHabitudCourses()
  }
  getHabitudCourses = async () => {
    const { locale } = this.props
    const habitudCourseUrls = await contentfulClient
      .getEntries({
        content_type: 'course',
        locale: locale === 'en' ? 'en-US' : locale,
      })
      .then(data => {
        return data.items
          .map(item => item.fields.url)
          .filter(Boolean)
          .sort((a, b) => {
            return (a === null) - (b === null) || -(a > b) || +(a < b)
          })
      })
    const habitudCourses = await getHabitudCoursesMetaData(habitudCourseUrls)
    this.setState({ habitudCourses })
  }

  render() {
    return (
      <NamespacesConsumer>
        {t => (
          <Sheet title={t('home.coursesSectionTitle')}>
            <Grid>
              {this.state.habitudCourses.map(courses => (
                <>
                  {courses.map(item => (
                    <HabitudCourse key={item.hCUrl} {...item} />
                  ))}
                </>
              ))}
            </Grid>
          </Sheet>
        )}
      </NamespacesConsumer>
    )
  }
}

export default CoursesSection
