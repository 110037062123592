import styled from 'styled-components/macro'

import { colors } from '../../../../globals/variables'

const EmptySectionPlaceholder = styled.div({
  color: colors.generalFontGrey,
  textAlign: 'left',
  opacity: '0.6',
  lineHeight: 1.5,
})

export default EmptySectionPlaceholder
