import i18n from 'i18next'

import generalEn from '../locales/general-en.json'
import generalSv from '../locales/general-sv.json'
import pagesEn from '../locales/pages-en.json'
import pagesSv from '../locales/pages-sv.json'

const resources = {
  en: {
    translation: { ...generalEn, ...pagesEn },
  },
  sv: {
    translation: { ...generalSv, ...pagesSv },
  },
}

i18n.init({
  resources,
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    wait: false,
    nsMode: 'fallback', // set it to fallback to let passed namespaces to translated hoc act as fallbacks
  },
})

export default i18n
