import React from 'react'
import styled from 'styled-components/macro'

const Field = styled.div({
  marginBottom: '10px',
})
const Label = styled.div({
  fontWeight: 600,
})

const FieldGroup = ({ label, input, ...rest }) => {
  return (
    <Field {...rest}>
      <Label>{label}</Label>
      {input}
    </Field>
  )
}

export default FieldGroup
