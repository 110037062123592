import React from 'react'

import FormButton from './FormButton'
import FlashMessage from './FlashMessage'

const SaveButtonWithFeedback = ({ data }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormButton>Submit</FormButton>
      {data && <FlashMessage style={{ marginLeft: 10 }} />}
    </div>
  )
}

export default SaveButtonWithFeedback
