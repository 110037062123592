export default [
  '#00335C',
  '#F67F9C',
  '#FFEA87',
  '#FF9970',
  '#00D7AC',
  '#FEE4EA',
  '#009577',
  '#5CC1FB',
]
