import React, { Component } from 'react'
import styled from 'styled-components/macro'
import AnimateHeight from 'react-animate-height'

import { concatenateTitle } from './principleTitle'
import principleColors from './principleColors'
import getDifference from './getDifference'
import HabitudCourse from '../../../common/HabitudCourse'
import differenceWithPlusMinus from './differenceWithPlusMinus'
import { colors } from '../../../../globals/variables'
import ArrowRight from '../../../common/svgs/ArrowRight'
import { PRINCIPLE_TAGS } from '../../../common/getHabitudCoursesMetaData'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
})
const Item = styled.div(({ index }) => ({
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '5px',
  marginBottom: '15px',
  border: '1px solid',
  borderColor: colors.generalGreyBorder,
  borderLeft: `5px solid ${principleColors[index]}`,
  marginRight: '20px',
  flex: 1,
}))
const PrincipleTitle = styled.div({
  textAlign: 'left',
  paddingRight: '15px',
  boxSizing: 'border-box',
  maxWidth: '400px',
  width: '100%',
  minWidth: '200px',
})
const Points = styled.div({
  minWidth: '90px',
  color: colors.generalFontGrey,
})
const Difference = styled.div({
  minWidth: '115px',
  color: colors.generalFontGrey,
})
const MoreCoursesLink = styled.a({
  margin: '20px 0',
  display: 'inline-block',
  backgroundColor: colors.defaultBlue,
  padding: '10px 20px',
  borderRadius: 21,
  color: '#fff',
  minWidth: 120,
})
const CoursesHeading = styled.h3({})
const Courses = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 20,
})
const MainRow = styled.div({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})
const StyledArrowRight = styled(ArrowRight)(({ isOpen }) => ({
  transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
  opacity: '0.6',
  transition: 'all 100ms',
}))
const ComparingData = styled.div({
  padding: '10px',
  borderRadius: '5px',
  margin: '0 0 15px auto',
  lineHeight: '20px',
  border: '1px solid',
  borderColor: colors.generalGreyBorder,
  maxWidth: '150px',
  width: '100%',
  color: colors.generalFontGrey,
})

class BottomSectionRow extends Component {
  state = {
    isOpen: false,
  }
  handleClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }
  render() {
    const {
      principle,
      index,
      locale,
      resultPrinciples,
      normPrinciples,
      habitudCourses,
      comparableResult,
      t,
    } = this.props
    const { isOpen } = this.state
    const points = resultPrinciples.find(
      resultPrinciple => resultPrinciple.id === parseInt(principle.id, 10)
    ).totalAverage
    const normValue = normPrinciples[index].averageNormValue
    const [difference, positive] = getDifference({
      points,
      normValue,
    })
    return (
      <Wrapper>
        <Item key={index} index={index}>
          <MainRow onClick={this.handleClick}>
            <PrincipleTitle>
              {concatenateTitle({
                title: principle.publicTitle[locale],
                number: index + 1,
              })}
            </PrincipleTitle>
            <Points>{points} / 100</Points>
            <Difference>
              {differenceWithPlusMinus({ difference, positive })}
            </Difference>
            <StyledArrowRight isOpen={isOpen} />
          </MainRow>
          {habitudCourses &&
            habitudCourses.length > 0 && (
              <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
                <div>
                  <CoursesHeading>
                    {t('report.bottomSection.recommendedCoursesHeading')}
                  </CoursesHeading>
                  <Courses>
                    {habitudCourses.map(habitudCourse => (
                      <HabitudCourse
                        key={habitudCourse.hCUrl}
                        {...habitudCourse}
                      />
                    ))}
                  </Courses>
                  <MoreCoursesLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.habitud.se/tags/${encodeURIComponent(
                      PRINCIPLE_TAGS[index]
                    )}`}
                  >
                    {t('report.bottomSection.moreCourses')}
                  </MoreCoursesLink>
                </div>
              </AnimateHeight>
            )}
        </Item>
        <ComparingData>
          {comparableResult ? comparableResult.totalAverage : '-'}
        </ComparingData>
      </Wrapper>
    )
  }
}

export default BottomSectionRow
