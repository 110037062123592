import React from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { renderStatusContent } from '../../conducted-surveys/index'
import TextOnlyButton from '../../../../globals/components/TextOnlyButton'

const ConductedSurveys = ({ items, teamId, organizationId }) => {
  return (
    <Table celled fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Result</Table.HeaderCell>
          <Table.HeaderCell>Is active</Table.HeaderCell>
          <Table.HeaderCell>Launched</Table.HeaderCell>
          <Table.HeaderCell>Respondents</Table.HeaderCell>
          <Table.HeaderCell>Finished rate</Table.HeaderCell>
          <Table.HeaderCell>Report</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          .map(({ id, isActive, createdAt, respondents, result }) => {
            const respondentsCount = respondents.length
            const percentageFinished = Math.floor(
              (respondents.filter(r => r.isFinished).length /
                respondentsCount) *
                100
            )
            return (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{renderStatusContent(result)}</Table.Cell>
                <Table.Cell>{isActive.toString()}</Table.Cell>
                <Table.Cell>
                  {moment(createdAt).format('YYYY-MM-DD HH:mm')}
                </Table.Cell>
                <Table.Cell>{respondentsCount}</Table.Cell>
                <Table.Cell>{percentageFinished} %</Table.Cell>
                <Table.Cell>
                  {result && result.valid ? (
                    <TextOnlyButton
                      as={Link}
                      to={`/sa/organizations/${organizationId}/team/${teamId}/reports/${id}`}
                    >
                      View
                    </TextOnlyButton>
                  ) : (
                    <div>-</div>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

export default ConductedSurveys
