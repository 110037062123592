import { gql } from 'apollo-boost'

import { fragments, secondaryFragments } from '../../graphql/fragments'
import { localFragments } from './localFragments'

export const ALL_CONDUCTED_SURVEYS = gql`
  query {
    conductedSurveys {
      id
      title
      identifier
      isActive
      createdAt
      endedAt
      message
      result
      launchedBy {
        id
      }
      team {
        name
        organization {
          name
        }
      }
      survey {
        title
      }
      respondents {
        id
        isFinished
      }
    }
  }
`
export const ORGANIZATIONS = gql`
  query {
    organizations {
      ...CompleteOrganization
      survey {
        title
        version
      }
      teams {
        ...CompleteTeam
        users {
          id
        }
      }
    }
  }
  ${localFragments.organization}
  ${secondaryFragments.team}
`
export const ORGANIZATION = gql`
  query($id: ID!) {
    organization(id: $id) {
      ...CompleteOrganization
      survey {
        title
        version
      }
      users {
        ...CompleteUser
      }
      teams {
        ...CompleteTeam
        conductedSurveys {
          ...CompleteConductedSurvey
          respondents {
            ...CompleteRespondent
            user {
              ...CompleteUser
            }
          }
        }
        users {
          ...UserWithTeamAccess
        }
      }
    }
  }
  ${localFragments.organization}
  ${fragments.user}
  ${secondaryFragments.team}
  ${fragments.userWithTeamAccess}
  ${fragments.conductedSurvey}
  ${secondaryFragments.respondent}
`
