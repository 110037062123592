import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import inputStyling, { placeholderStyle } from './inputStyling'

const StyledInput = styled.input`
  ${inputStyling};
  ${placeholderStyle};
`

const ControlledInput = ({ inputType, ...rest }) => (
  <StyledInput type={inputType} {...rest} />
)

ControlledInput.propTypes = {
  inputType: PropTypes.oneOf(['text', 'number', 'password', 'email', 'file'])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
}

export default ControlledInput
