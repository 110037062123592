import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { RESPONDENT } from '../../graphql/queries'
import i18n from '../../globals/i18n'

import { Error, Preloader } from '../../globals/components/ErrorAndPreloader'
import Controller from './Controller'
import NoSurvey from './NoSurvey'

const renderFlattenedSurvey = survey => {
  let flattenedSurvey = []
  survey.principles.forEach(({ subScales, ...principle }, index) => {
    flattenedSurvey.push({ principle: { ...principle, position: index + 1 } })
    subScales.forEach(({ questions }) => {
      questions.forEach(question => {
        flattenedSurvey.push({ question })
      })
    })
  })
  return flattenedSurvey
}
export const renderFlattenedSurveyAndPrinciples = survey => {
  let flattenedSurvey = renderFlattenedSurvey(survey)
  let principleTimeline = []
  flattenedSurvey = flattenedSurvey.map((item, index) => {
    if (Object.keys(item)[0] === 'principle') {
      principleTimeline.push({
        position: index + 1,
        principleId: Object.values(item)[0].id,
      })
    }
    return {
      [index]: item,
    }
  })
  return {
    flattenedSurvey,
    principleTimeline,
  }
}

class SurveyContainer extends Component {
  render() {
    const {
      conductedSurveyIdentifier,
      respondentIdentifier,
    } = this.props.match.params
    if (conductedSurveyIdentifier) {
      return (
        <Query
          query={RESPONDENT}
          variables={{
            respondentIdentifier,
            conductedSurveyIdentifier,
          }}
        >
          {({ error, loading, data }) => {
            if (error) return <Error />
            if (loading) return <Preloader />
            const { respondent } = data
            if (!respondent || !respondent.conductedSurvey.isActive) {
              return <NoSurvey />
            }
            const {
              conductedSurvey: { survey, locale },
              responses,
              isFinished,
            } = respondent
            setTimeout(() => i18n.changeLanguage(locale))
            const {
              flattenedSurvey,
              principleTimeline,
            } = renderFlattenedSurveyAndPrinciples(survey)
            return (
              <Controller
                {...{
                  locale,
                  flattenedSurvey,
                  responses,
                  principleTimeline,
                  isFinished,
                }}
              />
            )
          }}
        </Query>
      )
    }
    return null
  }
}

export default withRouter(SurveyContainer)
