import React from 'react'

import { SURVEY } from '../../../graphql/survey-constructor/queriesAndMutations'

import SettingsIcon from '../../../svgs/Settings'
import TransparentButton from '../../../../globals/components/TransparentButton'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'
import changePositionMutations from './changePositionMutations'
import { ActionBarIcon, ActionsWrapper } from './miscStyledComponentElements'

const ActionBar = ({
  obj: { id, position, ...obj },
  updateMutation,
  deleteMutation,
  surveyId,
  formFields,
  isHavingChildElements,
  ...rest
}) => {
  return (
    <ActionsWrapper>
      {changePositionMutations({
        position,
        surveyId,
        id,
        ...rest,
      })}
      <ModalWithFormAndMutation
        mutation={updateMutation}
        modalTitle={`Edit`}
        additionalVariables={{ id }}
        // {...(id === '10' ? { isModalVisible: true } : {})}
        modalTrigger={props => (
          <TransparentButton style={{ color: '#fff' }} {...props}>
            <ActionBarIcon as={SettingsIcon} />
          </TransparentButton>
        )}
        fields={formFields}
        bottomRight={
          !isHavingChildElements && {
            refetchQueries: {
              query: SURVEY,
              variables: { id: surveyId },
            },
            mutationObj: {
              mutation: deleteMutation,
              id,
            },
          }
        }
      />
    </ActionsWrapper>
  )
}

export default ActionBar
