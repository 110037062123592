export const isNumeric = value => {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export const roundOffToDecimals = ({ number, decimals }) => {
  return +(Math.round(number + 'e+' + decimals) + 'e-' + decimals)
}

export const decamelize = (str, separator) => {
  separator = typeof separator === 'undefined' ? ' ' : separator
  const s = str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()
  const string = s[0].toUpperCase() + s.slice(1)
  return string
}

export const capitalize = string => {
  return string && string.charAt(0).toUpperCase() + string.slice(1)
}
