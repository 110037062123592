import { css } from 'styled-components/macro'

export const placeholderStyle = css`
  ::placeholder {
    color: #bbb;
  }
`

export default css({
  padding: '0 15px',
  boxSizing: 'border-box',
  width: '100%',
  height: '40px',
  border: '1px solid #ddd',
  outline: 'none',
  marginBottom: '15px',
  borderRadius: '2px',
})
