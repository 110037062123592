import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { NamespacesConsumer } from 'react-i18next'

import PageWrapper from '../team-admin/common/PageWrapper'
import Navbar from '../team-admin/layout/Navbar'
import HomeContainer from '../team-admin/screens/home/HomeContainer'
import MembersContainer from '../team-admin/screens/members/MembersContainer'
import ConductedSurveyContainer from '../team-admin/screens/conducted-surveys/ConductedSurveyContainer'
import NewConductedSurveyContainer from '../team-admin/screens/conducted-surveys/NewConductedSurveyContainer'
import ReportContainer from '../team-admin/screens/report/ReportContainer'
import Account from '../team-admin/screens/account/Account'

const TeamAdminRoutes = ({ currentUser }) => {
  const team = currentUser.defaultTeam
  return (
    <NamespacesConsumer>
      {t => (
        <>
          <Navbar currentUser={currentUser} />
          <PageWrapper>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.home')}</title>
                    </Helmet>
                    <HomeContainer />
                  </>
                )}
              />
              <Route
                path="/members"
                render={() => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.members')}</title>
                    </Helmet>
                    <MembersContainer teamId={team.id} />
                  </>
                )}
              />
              <Route
                path="/conducted-survey/new"
                render={() => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.newConductedSurvey')}</title>
                    </Helmet>
                    <NewConductedSurveyContainer team={team} />
                  </>
                )}
              />
              <Route
                path="/conducted-survey/:id"
                render={() => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.pendingConductedSurvey')}</title>
                    </Helmet>
                    <ConductedSurveyContainer />
                  </>
                )}
              />
              <Route
                path="/report/:id"
                render={props => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.report')}</title>
                    </Helmet>
                    <ReportContainer
                      {...props}
                      locale={currentUser.locale}
                      team={team}
                    />
                  </>
                )}
              />
              <Route
                path="/account"
                render={() => (
                  <>
                    <Helmet>
                      <title>{t('pageTitles.account')}</title>
                    </Helmet>
                    <Account currentUser={currentUser} />
                  </>
                )}
              />
              <Redirect to="/" />
            </Switch>
          </PageWrapper>
        </>
      )}
    </NamespacesConsumer>
  )
}

export default TeamAdminRoutes
