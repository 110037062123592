import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import SurveyContainer from '../survey-conduction/screens/SurveyContainer'

const SurveyConductionRoutes = () => {
  return (
    <Switch>
      <Route
        path="/survey/:conductedSurveyIdentifier/:respondentIdentifier/:step?"
        render={() => <SurveyContainer />}
      />
      <Redirect to="/signin" />
    </Switch>
  )
}

export default SurveyConductionRoutes
