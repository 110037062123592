import React from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Preloader from '../../../globals/components/Preloader'
import Error from '../../../globals/components/Error'

import Organization from './Organization'
import { ORGANIZATION } from '../../graphql/queries'

const OrganizationContainer = props => {
  return (
    <Query query={ORGANIZATION} variables={{ id: props.match.params.id }}>
      {({ loading, error, data }) => {
        if (loading) return <Preloader />
        if (error) return <Error />
        console.log('OrganizationContainer', data.organization);
        return <Organization {...props} item={data.organization} />
      }}
    </Query>
  )
}

export default withRouter(OrganizationContainer)
