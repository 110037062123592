import { NamespacesConsumer } from 'react-i18next'
import { Query, withApollo } from 'react-apollo'
import React from 'react'
import styled from 'styled-components/macro'

import { CURRENT_USER, CONDUCTED_SURVEYS } from '../../../graphql/queries'
import { Error, Preloader } from '../../../globals/components/ErrorAndPreloader'
import { colors } from '../../../globals/variables'
import Button from '../../common/Button'
import CoursesSection from './bits/CoursesSection'
import EmptySectionPlaceholder from './bits/EmptySectionPlaceholder'
import Sheet from '../../common/Sheet'
import SurveySection from './bits/SurveySection'

const CrossLinks = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: inline-grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
`

const HomeContainer = ({ client }) => {
  const {
    currentUser: {
      locale,
      defaultTeam: { id: teamId },
    },
  } = client.readQuery({ query: CURRENT_USER })
  return (
    <NamespacesConsumer>
      {t => (
        <Query query={CONDUCTED_SURVEYS}>
          {({ data, loading, error }) => {
            if (loading) return <Preloader />
            if (error) return <Error />
            return (
              <>
                <SurveySection
                  conductedSurveys={data.conductedSurveys}
                  teamId={teamId}
                />
                <Sheet title={t('home.challengeSectionTitle')}>
                  <EmptySectionPlaceholder>
                    {t('home.challengeSectionEmptyPlaceholder')}
                  </EmptySectionPlaceholder>
                  <CrossLinks>
                    <a
                      href="https://www.habitud.se/users/profile"
                      target="_blank"
                    >
                      <Button>{t('home.challengeSectionSavedTips')}</Button>
                    </a>
                    <a
                      href="https://www.habitud.se/courses/browse?selection=most_popular"
                      target="_blank"
                    >
                      <Button
                        style={{ backgroundColor: colors.screamingGreen }}
                      >
                        {t('home.challengeSectionExploreTips')}
                      </Button>
                    </a>
                  </CrossLinks>
                </Sheet>
                <CoursesSection locale={locale} />
              </>
            )
          }}
        </Query>
      )}
    </NamespacesConsumer>
  )
}

export default withApollo(HomeContainer)
