import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import Button from '../common/Button'
import Title from '../common/Title'
import Lock from '../svgs/Lock'
import Timer from '../svgs/Timer'
import ChatBubble from '../svgs/ChatBubble'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const Terms = styled.div({
  marginTop: '30px',
  fontSize: '12px',
  opacity: 0.6,
})
const Icons = styled.div({
  display: 'flex',
  minHeight: '150px',
  justifyContent: 'space-between',
  marginTop: '50px',
})
const Text = styled.div({})
const IconWithText = styled.div({
  width: '90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})
const svgStyling = { viewBox: '0 0 30 30', style: { width: 60, height: 60 } }

const Start = () => (
  <NamespacesConsumer>
    {t => (
      <Wrapper>
        <Title data-testid={'startScreen'}>{t('survey.start.mainTitle')}</Title>
        <div>{t('survey.start.subTitle')}</div>
        <Icons>
          <IconWithText>
            <ChatBubble {...svgStyling} />
            <Text>{t('survey.start.questionsIconDescr')}</Text>
          </IconWithText>
          <IconWithText>
            <Timer {...svgStyling} />
            <Text>{t('survey.start.timeIconDescr')}</Text>
          </IconWithText>
          <IconWithText>
            <Lock {...svgStyling} />
            <Text>{t('survey.start.anonymityIconDescr')}</Text>
          </IconWithText>
        </Icons>
        <Button as={Link} to="1" style={{ marginTop: 30 }}>
          {t('survey.start.button')}
        </Button>
        <Terms>{t('survey.start.terms')}</Terms>
      </Wrapper>
    )}
  </NamespacesConsumer>
)

export default Start
