import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { colors, borderRadiuses } from '../globals/variables'

const Wrapper = styled.div({
  minHeight: 65,
  marginBottom: 20,
})

const Button = styled.button({
  border: `1px solid ${colors.defaultBlue}`,
  backgroundColor: '#fff',
  display: 'block',
  width: '100%',
  padding: 15,
  cursor: 'pointer',
  borderRadius: borderRadiuses.defaultSheet,
  textAlign: 'center',
  lineHeight: 0,
})

const Avatar = styled.img({
  borderRadius: '50%',
  height: 33,
  width: 33,
  marginRight: 7,
})

const User = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  lineHeight: 1.2,
})

export default function AutoSignin({ t }) {
  const [{ email, firstName, lastName, avatarUrl, token }, setData] = useState(
    {}
  )
  useEffect(() => {
    window.addEventListener(
      'message',
      async e => {
        if (e.data.token) {
          setData(e.data)
        }
      },
      false
    )
  }, [])
  const src = /localhost:/.test(window.location.href)
    ? 'http://localhost:4000/sso'
    : 'https://habitud.se/sso'
  return (
    <Wrapper>
      <iframe
        title="hidden"
        src={src}
        style={{ position: 'absolute', height: 0, width: 0, border: 'none' }}
      />
      {email && (
        <Button
          onClick={() => {
            localStorage.setItem('token', token)
            window.location.href = '/'
          }}
        >
          <User>
            <Avatar src={avatarUrl} alt={`${firstName} ${lastName}`} />
            <span>{t('signin.autoSignin', { firstName, lastName })}</span>
          </User>
        </Button>
      )}
    </Wrapper>
  )
}
