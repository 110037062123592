import React from 'react'
import { withRouter } from 'react-router-dom'

import Start from './Start'
import End from './End'
import FinalSubmission from './FinalSubmission'
import Principle from './Principle'
import Question from './Question'
import ScreenWrapper from '../common/ScreenWrapper'
import ScreenWrapperNonSurvey from '../common/ScreenWrapperNonSurvey'
import Timeline from '../layout/Timeline'

const matchItemWithCursor = (item, step) =>
  Number(Object.keys(item)[0]) === step

export const getPageItem = ({ step, flattenedSurvey, isFinished }) => {
  if (isFinished || step === 'fin') return { objectName: 'end' }
  if (!step || step === '0') return { objectName: 'start' }
  if (step === (flattenedSurvey.length + 1).toString())
    return { objectName: 'finalSubmission' }
  const nestedItem = flattenedSurvey.find(item =>
    matchItemWithCursor(item, step - 1)
  )
  const item = Object.values(nestedItem)[0]
  const objectName = Object.keys(item)[0]
  const surveyItem = Object.values(item)[0]
  return { surveyItem, objectName }
}

const Controller = ({
  locale,
  responses,
  principleTimeline,
  flattenedSurvey,
  match,
  history,
  isFinished,
}) => {
  const { step } = match.params
  const { objectName, surveyItem } =
    getPageItem({ step, flattenedSurvey, isFinished }) || {}

  const handleNextPrevClick = ({ direction }) => {
    const currentStep = Number(step) || 0
    const change = direction === 'backward' ? -1 : +1
    const nextStep = currentStep + change
    history.push(nextStep.toString())
  }

  let content
  switch (objectName) {
    case 'principle': {
      content = (
        <Principle {...{ ...surveyItem, locale, handleNextPrevClick }} />
      )
      break
    }
    case 'question': {
      const response = responses.find(
        item => item.question.id === surveyItem.id
      )
      const { value: responseValue, id: responseId } = { ...response }
      content = (
        <Question
          {...{
            ...surveyItem,
            locale,
            responseValue,
            responseId,
            handleNextPrevClick,
          }}
        />
      )
      break
    }
    case 'finalSubmission': {
      return (
        <ScreenWrapperNonSurvey>
          <FinalSubmission />
        </ScreenWrapperNonSurvey>
      )
    }
    case 'end': {
      return (
        <ScreenWrapperNonSurvey>
          <End />
        </ScreenWrapperNonSurvey>
      )
    }
    default: {
      return (
        <ScreenWrapperNonSurvey>
          <Start />
        </ScreenWrapperNonSurvey>
      )
    }
  }
  return (
    <ScreenWrapper backroundColor={objectName === 'principle' && '#fff'}>
      <Timeline principleTimeline={principleTimeline} />
      {content}
    </ScreenWrapper>
  )
}

export default withRouter(Controller)
