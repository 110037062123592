import React from 'react'
import { NamespacesConsumer } from 'react-i18next'

import { UPDATE_USER } from '../../../graphql/mutations'

import Sheet from '../../common/Sheet'
import FormConstructor from '../../common/FormConstructor'

export const userFormFields = ({
  firstName = null,
  lastName = null,
  email = null,
  password = null,
} = {}) => [
  {
    inputType: 'text',
    value: { firstName: firstName || '' },
    dataTestid: 'firstNameField',
  },
  {
    inputType: 'text',
    value: { lastName: lastName || '' },
  },
  {
    inputType: 'text',
    value: { email: email || '' },
  },
  {
    inputType: 'password',
    value: { password: password || '' },
  },
]

const Account = ({ currentUser }) => {
  const { firstName, lastName, email, id } = currentUser

  return (
    <NamespacesConsumer>
      {t => (
        <Sheet narrow subPage title={t('account.mainTitle')}>
          <div style={{ maxWidth: 400 }}>
            <FormConstructor
              mutation={UPDATE_USER}
              additionalVariables={{ id }}
              fields={userFormFields({ firstName, lastName, email })}
              buttonText={t('account.button')}
              successMsg={t('account.successMessage')}
            />
          </div>
        </Sheet>
      )}
    </NamespacesConsumer>
  )
}

export default Account
