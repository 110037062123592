import React from 'react'

import { ApolloConsumer } from 'react-apollo'

const Signout = ({ someRenderProps }) => (
  <ApolloConsumer>
    {client => {
      return someRenderProps({
        onClick: () => {
          localStorage.setItem('token', '')
          client.cache.reset()
          window.location.reload()
        },
      })
    }}
  </ApolloConsumer>
)

export default Signout
