import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../globals/variables'

const Wrapper = styled.div({
  backgroundColor: '#fff',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  color: colors.generalFontGrey,
  paddingLeft: '20px',
  paddingRight: '20px',
})
const Column = styled.div({
  maxWidth: '400px',
  width: '100%',
  marginTop: '100px',
})

const ScreenWrapperNonSurvey = ({ children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Column>{children}</Column>
    </Wrapper>
  )
}

export default ScreenWrapperNonSurvey
