import React from 'react'
import styled from 'styled-components/macro'

import { colors, boxShadows, borderRadiuses } from '../../globals/variables'

const paddingWidth = '50px'

const Wrapper = styled.div(({ narrow }) => ({
  backgroundColor: '#fff',
  width: narrow ? '700px' : '900px',
  minHeight: '300px',
  marginBottom: '50px',
  paddingBottom: '10px',
  borderRadius: borderRadiuses.defaultSheet,
  boxShadow: boxShadows.defaultSheet,
  maxWidth: '100%',
}))

const Title = styled.div(props => ({
  fontSize: '25px',
}))
const TopSection = styled.div(props => {
  const borderBottom = props.subPage
    ? `1px solid ${colors.generalGreyBorder}`
    : '0'
  return {
    display: 'flex',
    alignItems: 'center',
    marginLeft: paddingWidth,
    marginRight: paddingWidth,
    paddingTop: '40px',
    paddingBottom: '20px',
    ...{ borderBottom },
  }
})
const Content = styled.div(props => ({
  margin: props.subPage ? `20px ${paddingWidth}` : `40px ${paddingWidth}`,
}))

const TopRight = styled.div({
  marginLeft: 'auto',
})

const SectionSheet = ({ title, narrow, children, topRight, subPage }) => {
  return (
    <Wrapper narrow={narrow}>
      {title && (
        <TopSection subPage={subPage}>
          <Title subPage={subPage}>{title}</Title>
          {topRight && <TopRight>{topRight}</TopRight>}
        </TopSection>
      )}
      <Content subPage={subPage}>{children}</Content>
    </Wrapper>
  )
}

export default SectionSheet
