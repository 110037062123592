import React from 'react'

import AddButton, { AddWrapper } from '../../../common/AddButton'
import ModalWithFormAndMutation from '../../../common/form/ModalWithFormAndMutation'
import { SURVEY } from '../../../graphql/survey-constructor/queriesAndMutations'
import {
  CREATE_PRINCIPLE,
  CREATE_SUBSCALE,
  CREATE_QUESTION,
} from '../../../graphql/survey-constructor/queriesAndMutations'

const principlesAsSelectOptions = items =>
  items.map(item => {
    return {
      key: item.id,
      value: item.id,
      text: `${item.internalTitle} (ID: ${item.id})`,
    }
  })

const subScalesAsSelectOptions = principles => {
  let arrays = []
  principles.forEach(({ subScales, ...principle }) => {
    const subSet = subScales.map(subScale => {
      return {
        key: subScale.id,
        value: subScale.id,
        text: `${principle.internalTitle}: ${subScale.internalTitle} (ID: ${
          subScale.id
        })`,
      }
    })
    arrays.push(subSet)
  })
  return arrays.reduce((a, b) => a.concat(b), [])
}

const renderModalWithFormAndMutation = ({
  surveyId,
  mutation,
  modalTitle,
  fields,
}) => (
  <ModalWithFormAndMutation
    {...{ modalTitle, mutation, fields }}
    additionalVariables={{ surveyId }}
    refetchQueries={[
      {
        query: SURVEY,
        variables: { id: surveyId },
      },
    ]}
    modalTrigger={props => (
      <AddButton style={{ marginRight: 15 }} {...props}>
        {modalTitle}
      </AddButton>
    )}
  />
)

const AdditionGroup = ({ principles, ...props }) => {
  return (
    <AddWrapper>
      {renderModalWithFormAndMutation({
        ...props,
        modalTitle: 'Create new Principle',
        mutation: CREATE_PRINCIPLE,
        fields: [],
      })}
      {renderModalWithFormAndMutation({
        ...props,
        modalTitle: 'Create new SubScale',
        mutation: CREATE_SUBSCALE,
        fields: [
          {
            inputType: 'selectInput',
            value: { principleId: '' },
            options: principlesAsSelectOptions(principles),
            placeholder: 'Choose principle',
          },
        ],
      })}
      {renderModalWithFormAndMutation({
        ...props,
        modalTitle: 'Create new Question',
        mutation: CREATE_QUESTION,
        fields: [
          {
            inputType: 'selectInput',
            value: { subScaleId: '' },
            options: subScalesAsSelectOptions(principles),
            placeholder: 'Choose sub scale',
          },
        ],
      })}
    </AddWrapper>
  )
}

export default AdditionGroup
