import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import inputStyling from './inputStyling'

const Wrapper = styled.div`
  position: relative;
  display: inline;

  select {
    ${inputStyling};
  }
`

const ControlledSelectInput = ({
  selectedOption,
  placeholder,
  options,
  controlFunc,
  className,
  ...props
}) => (
  <Wrapper className={className}>
    <select onChange={controlFunc} value={selectedOption} {...props}>
      {placeholder && <option value="">{placeholder}</option>}
      {options.map(opt => {
        return (
          <option key={opt.value} value={opt.value}>
            {opt.text}
          </option>
        )
      })}
    </select>
  </Wrapper>
)

ControlledSelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  controlFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default ControlledSelectInput
