import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div(({ main }) => ({
  backgroundColor: main ? '#ecf3fd' : '#fff',
  borderRadius: '2px',
  marginBottom: '40px',
  boxShadow: '0 2px 5px 0 #33334f1a',
}))
const TopSection = styled.div(({ main }) => ({
  padding: '0 35px',
  borderBottom: '1px solid',
  borderColor: main ? '#dfe1e4' : '#e8e8e8',
}))
const MainRow = styled.div({
  minHeight: '70px',
  display: 'flex',
  alignItems: 'center',
})
const Title = styled.div({
  fontSize: '16px',
  padding: '16px 0',
  fontWeight: 600,
})
const Content = styled.div({
  padding: '24px 35px',
})
const TopRight = styled.div({
  marginLeft: 'auto',
})

const Sheet = ({ title, children, topRight, main, icon, meta, ...rest }) => {
  return (
    <Wrapper main={main} {...rest}>
      <TopSection main={main}>
        <MainRow>
          {icon && (
            <div style={{ marginRight: 10, display: 'flex' }}>{icon}</div>
          )}
          <Title>{title}</Title>
          {topRight && <TopRight>{topRight}</TopRight>}
        </MainRow>
        {meta && <div>{meta}</div>}
      </TopSection>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default Sheet
