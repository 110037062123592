import React from 'react'

import ControlledTextarea from './ControlledTextarea'

const ControlledJsonTextarea = ({
  objName,
  values,
  handleJsonChange,
  ...rest
}) => {
  return Object.keys(values).map(key => (
    <div key={key}>
      <div>{key}</div>
      <ControlledTextarea
        name={`${objName}-${key}`}
        value={values[key]}
        onChange={event => handleJsonChange(objName, key, event)}
        {...rest}
      />
    </div>
  ))
}

export default ControlledJsonTextarea
