import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next'

import { CREATE_CONDUCTED_SURVEY } from '../../../graphql/mutations'
import { CURRENT_USER, CONDUCTED_SURVEYS } from '../../../graphql/queries'

import Sheet from '../../common/Sheet'
import Button from '../../common/Button'
import Section from './bits/Section'
import SummarySection from './bits/SummarySection'
import RespondentListSection from './bits/RespondentListSection'
import MessageSection from './bits/MessageSection'
import Error from '../../../globals/components/Error'
import Preloader from '../../../globals/components/Preloader'

class NewConductedSurvey extends Component {
  state = {
    message: '',
    includeAdmins: false,
  }
  handleChange = event => {
    this.setState({
      message: event.target.value,
    })
  }
  handleSubmit(event, mutationFunction) {
    event.preventDefault()
    mutationFunction().then(({ data }) => {
      this.props.history.push('/')
    })
  }
  render() {
    const { teamId, client, respondents } = this.props
    const { currentUser } = client.readQuery({ query: CURRENT_USER })
    const { message, includeAdmins } = this.state
    return (
      <NamespacesConsumer>
        {t => (
          <Sheet narrow subPage title={t('conductedSurvey.new.mainTitle')}>
            <SummarySection
              title={t('conductedSurvey.new.sections.summaryTitle')}
              content={t('conductedSurvey.new.sections.summaryContent', {
                respondentCount: t('personWithCount', {
                  count: respondents.length,
                }),
              })}
            />
            <RespondentListSection
              title={t('conductedSurvey.new.sections.respondentsTitle')}
              respondents={respondents}
            />
            <Section>
              <label style={{ fontWeight: 'bold' }}>
                <input
                  type="checkbox"
                  value={includeAdmins}
                  onChange={e =>
                    this.setState({ includeAdmins: e.target.checked })
                  }
                />{' '}
                {t('conductedSurvey.new.sections.includeAdmins')}
              </label>
            </Section>
            <MessageSection
              title={t('conductedSurvey.new.sections.messageTitle')}
              content={t('conductedSurvey.new.sections.messageContent')}
              handleChange={this.handleChange}
              value={message}
            />
            <Mutation
              mutation={CREATE_CONDUCTED_SURVEY}
              variables={{
                teamId,
                message,
                launchedById: currentUser.id,
                includeAdmins,
              }}
              refetchQueries={[
                { query: CONDUCTED_SURVEYS, variables: { teamId } },
              ]}
            >
              {(mutationFunction, { data, error, loading }) => {
                if (loading) return <Preloader />
                if (error) return <Error />
                return (
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={event =>
                      this.handleSubmit(event, mutationFunction)
                    }
                  >
                    {t('conductedSurvey.new.button')}
                  </Button>
                )
              }}
            </Mutation>
          </Sheet>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withRouter(withApollo(NewConductedSurvey))
