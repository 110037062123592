import React from 'react'
import { Table } from 'semantic-ui-react'

import multipleSort from '../../../../globals/multipleSort'
import TeamUsersRow from './TeamUsersRow'

const TeamUsers = ({ items, id, organizationId }) => {
  console.log('TeamUser items', items);
  return (
    <Table celled fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.sort(multipleSort(['role', 'firstName'])).map(item => (
          <TeamUsersRow
            key={item.id}
            teamId={id}
            item={item}
            organizationId={organizationId}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export default TeamUsers
