export default ({ orgUsers, teamUsers }) => {
  const teamUsersIds = teamUsers.map(item => item.id)
  return orgUsers
    .filter(orgUser => !teamUsersIds.includes(orgUser.id))
    .map(orgUser => {
      return {
        key: orgUser.id,
        value: orgUser.id,
        text: `${orgUser.firstName} ${orgUser.lastName}`
      }
    })
}
