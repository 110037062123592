import React from 'react'
import styled from 'styled-components/macro'

import {
  UPDATE_TEAM_ACCESS,
  DELETE_TEAM_ACCESS,
} from '../../../../graphql/mutations'

import { colors } from '../../../../globals/variables'
import ActionButton from '../../../common/ActionButton'
import DirectActionMutation from '../../../common/DirectActionMutation'

const Item = styled.div({
  borderBottom: `1px solid ${colors.generalGreyBorder}`,
  padding: '12px 0',
  display: 'flex',
  alignItems: 'center',
})
const LeftSide = styled.div({})
const Name = styled.div({
  fontSize: '16px',
  marginBottom: '1px',
  display: 'flex',
  alignItems: 'center',
})
const AdminBadge = styled.div({
  backgroundColor: colors.defaultBlue,
  color: '#fff',
  fontSize: '11px',
  borderRadius: '3px',
  padding: '2px 6px',
  marginLeft: '8px',
})
const Email = styled.div({
  color: colors.generalFontGrey,
})
const RightSide = styled.div({
  color: colors.defaultBlue,
  marginLeft: 'auto',
  display: 'flex',
})

const UserRow = ({
  id,
  firstName,
  lastName,
  email,
  isActive,
  teamId,
  role,
  t,
}) => {
  return (
    <Item>
      <LeftSide>
        <Name>
          {firstName} {lastName}
          {role === 'admin' && (
            <AdminBadge>{t('members.roleName.admin')}</AdminBadge>
          )}
        </Name>
        <Email href={`mailto:${email}`}>{email}</Email>
      </LeftSide>
      <RightSide>
        {isActive ? (
          <DirectActionMutation
            mutation={UPDATE_TEAM_ACCESS}
            variables={{ teamId, userId: id, isActive: false }}
            modalTitle={t('members.actions.pause.modalTitle', {
              firstName,
              lastName,
            })}
            contentText={t('members.actions.pause.modalDescr')}
            buttonText={t('members.actions.pause.modalButton')}
            successMsg={t('members.actions.pause.successMessage', {
              firstName,
              lastName,
            })}
            modalTrigger={props => {
              return (
                <ActionButton {...props}>
                  {t('members.actions.pause.triggerLabel')}
                </ActionButton>
              )
            }}
          />
        ) : (
          <>
            <DirectActionMutation
              mutation={UPDATE_TEAM_ACCESS}
              variables={{ teamId, userId: id, isActive: true }}
              modalTitle={t('members.actions.activate.modalTitle', {
                firstName,
                lastName,
              })}
              contentText={t('members.actions.activate.modalDescr')}
              buttonText={t('members.actions.activate.modalButton')}
              successMsg={t('members.actions.activate.successMessage', {
                firstName,
                lastName,
              })}
              modalTrigger={props => {
                return (
                  <ActionButton {...props}>
                    {t('members.actions.activate.triggerLabel')}
                  </ActionButton>
                )
              }}
            />
            <DirectActionMutation
              mutation={DELETE_TEAM_ACCESS}
              variables={{ teamId, userId: id }}
              modalTitle={t('members.actions.remove.modalTitle', {
                firstName,
                lastName,
              })}
              contentText={t('members.actions.remove.modalDescr')}
              buttonText={t('members.actions.remove.modalButton')}
              successMsg={t('members.actions.remove.successMessage', {
                firstName,
                lastName,
              })}
              modalTrigger={props => {
                return (
                  <ActionButton style={{ marginLeft: 10 }} {...props}>
                    {t('members.actions.remove.triggerLabel')}
                  </ActionButton>
                )
              }}
            />
          </>
        )}
      </RightSide>
    </Item>
  )
}

export default UserRow
